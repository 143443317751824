import useScreenType from 'hooks/useScreenType';
import { Button, Tooltip } from 'components/atoms';
import { RiInformationFill } from 'react-icons/ri';
import { useChatBot } from 'hooks/useChatBot';
import * as S from '../Styles';

const KYCNotification = () => {
  const { isTablet, isMobile } = useScreenType();
  const { verifyChat } = useChatBot();
  const message = isMobile
    ? 'Account limited, verify your identity.'
    : ' Your account is currently limited. Please verify your account by updating your KYC.';

  return (
    <S.AlertWrapper className='z-20'>
      <S.CustomAlert
        message={
          <div className='flex items-center'>
            <Tooltip
              title={
                <div className='text-13 leading-6'>
                  <p>- The number of concurrent calls is limited to two.</p>
                  <p>- The maximum duration of an incoming and outgoing call is 15 minutes.</p>
                </div>
              }
            >
              <RiInformationFill className='text-white mr-1 text-base flex-shrink-0' />
            </Tooltip>
            <p className='inline-block'>
              {message}{' '}
              <Button type='ghost' onClick={verifyChat} className='btn-outline'>
                Verify now
              </Button>
            </p>
          </div>
        }
        type='warning'
        banner
        className={isTablet || isMobile ? 'alert-sm' : ''}
      />
    </S.AlertWrapper>
  );
};

export default KYCNotification;
