import styled from 'styled-components';

export const WidgetBody = styled.div`
  .loading::after {
    width: 0px;
    display: inline-block;
    animation: dotty steps(1, end) 2s infinite;
    content: '';
  }

  @keyframes dotty {
    0% {
      content: '';
    }
    25% {
      content: '.';
    }
    50% {
      content: '..';
    }
    75% {
      content: '...';
    }
    100% {
      content: '';
    }
  }
`;

export const FooterBtnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 32px 24px;
  > .ant-btn {
    border: none;
    &:hover,
    &:focus {
      border-color: inherit;
    }
  }
  > .ant-btn.end-call {
    margin: 0 auto;
    background: #db312b;
    width: 54px;
    height: 54px;
    border-radius: 50%;
    &:focus,
    &:hover {
      background: #bc1f27;
    }
  }
`;
