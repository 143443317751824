import * as path from 'constants/routes';
import { roles } from 'constants/roles';

export type MenuItem = {
  key: string;
  label?: string;
  isProtected?: boolean;
  hidden?: boolean;
};

export type Sidebar = {
  group: string;
  roleList: number[];
  menus: MenuItem[];
};

export const sidebar: Sidebar[] = [
  {
    group: 'USER SETTINGS',
    roleList: [roles.OWNER, roles.ADMIN, roles.MEMBER],
    menus: [
      {
        key: path.PROFILE,
        label: 'My Account',
      },
    ],
  },
  {
    group: 'WORKSPACE',
    roleList: [roles.OWNER, roles.ADMIN, roles.MEMBER],
    menus: [
      {
        key: path.WORKSPACE,
        label: 'Overview',
        isProtected: true,
      },
      {
        key: path.MEMBERS,
        label: 'Members',
      },
      {
        key: path.NUMBERS,
        label: 'My Numbers',
      },
      {
        key: path.TEAM,
        label: 'Teams',
        isProtected: true,
      },
      {
        key: path.TAGS,
        label: 'Contact Tags',
      },
      {
        key: path.BLOCK,
        label: 'Blocklist',
      },
    ],
  },
  {
    group: 'BILLING',
    roleList: [roles.OWNER, roles.ADMIN],
    menus: [
      {
        key: path.CREDIT,
        label: 'Credit',
      },
      {
        key: path?.PLAN_SUBS,
        label: 'Plan Subscription',
      },
    ],
  },
  {
    group: 'APP SETTINGS',
    roleList: [roles.OWNER, roles.ADMIN, roles.MEMBER],
    menus: [
      {
        key: path.DEVICES,
        label: 'Mic and Audio',
      },
      {
        key: path.NOTIFICATION,
        label: 'Notifications',
      },
      {
        key: path.REPORTS,
        label: 'Reports',
        isProtected: true,
        hidden: process.env.REACT_APP_ENABLE_USAGE_INSIGTS_REPORTS !== 'true',
      },
      {
        key: path.INTEGRATION,
        label: 'Integrations',
      },
      {
        key: path.DEVELOPER,
        label: 'Developer',
      },
      {
        key: path.LANGUAGE_TIMEZONES,
        label: 'Time & Language',
      },
    ],
  },
];
