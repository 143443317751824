import { useEffect, useState } from 'react';
import { Checkbox } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

import { Maybe, Tag } from 'generated/graphql';
import { useTagsQuery } from 'components/pages/settings/tags/hooks/useTagsQuery';

import { CheckboxWrapper, StyledTagOptionLabel } from './Styles';
import { useTag } from '../hooks/useTag';
import SaveDiscardActionBlock from '../save-discard-action';
import useActiveCallWidgetContext from '../../hooks/useActiveCallWidgetContext';
import useCallWidgetDrawerContext from '../../hooks/useCallWidgetDrawerContext';

function filterObjectsById(arrayOfIds: CheckboxValueType[], arrayOfObjects: Maybe<Tag>[]) {
  return arrayOfObjects.filter(obj => arrayOfIds.includes(obj?.id));
}

const TagsAssign = () => {
  const { saveButtonLabel, closeDrawer } = useCallWidgetDrawerContext();
  const [selectedTags, setSelectedTags] = useState<Maybe<Tag>[]>([]);
  const selectedTagIds = selectedTags?.map(tag => tag?.id);
  const { clientInfo } = useActiveCallWidgetContext();
  const { tags } = clientInfo || {};
  const { tagList, onSearch } = useTagsQuery();

  const { assignTags, loadingAssignTags } = useTag();

  const onChange = (checkedValues: CheckboxValueType[]) => {
    const updatedTags = filterObjectsById(checkedValues, tagList);
    setSelectedTags([...updatedTags]);
  };

  useEffect(() => {
    if (tags?.length) {
      setSelectedTags(tags);
    }
  }, [tags]);

  const handleTagSave = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    assignTags(selectedTags);
  };
  const tagOptions = tagList?.map((tag: Maybe<Tag>) => ({
    value: tag?.id,
    label: (
      <StyledTagOptionLabel>
        <span id='dot' style={{ backgroundColor: tag?.colorCode ?? '#99949E' }} />
        <p>{tag?.title}</p>
      </StyledTagOptionLabel>
    ),
    colorCode: tag?.colorCode,
  }));

  return (
    <div className='p-4 h-full flex flex-col'>
      <CheckboxWrapper>
        <Checkbox.Group options={tagOptions} value={selectedTagIds} onChange={onChange} />
      </CheckboxWrapper>
      <SaveDiscardActionBlock
        onDiscard={closeDrawer}
        onSave={handleTagSave}
        isSaveLoading={loadingAssignTags}
        saveButtonLabel={saveButtonLabel}
      />
    </div>
  );
};

export default TagsAssign;
