import { useTwilioContext } from 'lib/twilio';
import { useChannelsQuery } from 'hooks/useChannelsQuery';
import { usePhoneQuery } from 'components/organisms/widgets/call-widget/hooks/usePhoneQuery';

export function useCallWidgetHandler() {
  const { numbers: channels, loading, called } = useChannelsQuery();

  const {
    state: { channelId },
  } = useTwilioContext();

  const channelInfo = usePhoneQuery({ channelId });

  return {
    channelInfo,
    channels,
    loadingChannels: loading,
    channelsQueryCalled: called,
  };
}
