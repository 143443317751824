import { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { RiAddLine } from 'react-icons/ri';

import { AuthContext } from 'contexts/auth/AuthContext';
import { Icon, InfoButton } from 'components/atoms';
import useRouteChecker from 'components/pages/layouts/useRouteChecker';
import * as path from 'constants/routes';

const NumbersNotFound = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { isDialerPage } = useRouteChecker({
    pathname,
  });
  const { accessToken } = useContext(AuthContext);
  const accessTokenDecoded = accessToken && jwtDecode<any>(accessToken);
  const role: string = accessTokenDecoded?.user_claims?.role;
  const isMember = role === 'Member';
  const titleText = 'Dialing Number Not Found';
  const infoText = 'Make calls by setting up a phone number or notifying admin to do so.';

  const routeToBuyNumber = () => {
    if (isDialerPage) {
      window.open(`${path.SETTINGS}${path.NUMBERS}${path.BUY}`, '_blank');
      return;
    }
    history.push(`${path.SETTINGS}${path.NUMBERS}${path.BUY}`);
  };

  const routeToNumberList = () => {
    if (isDialerPage) {
      window.open(`${path.SETTINGS}${path.NUMBERS}`, '_blank');
      return;
    }
    history.push(`${path.SETTINGS}${path.NUMBERS}`);
  };

  return (
    <>
      <div className='rounded-lg h-full py-6 px-4 flex flex-col items-center justify-center'>
        <Icon name='numbers-not-found' className='mt-35' />
        <h6 className='mt-7 font-medium text-lg leading-5 text-gray-700'>{titleText}</h6>
        <p className='mt-2 mb-11 text-sm text-gray max-w-260 text-center'>{infoText}</p>
        <InfoButton onClick={routeToBuyNumber} size='large'>
          Purchase Number
        </InfoButton>
        {!isMember && (
          <button
            className='flex items-center mt-6 text-info'
            type='button'
            onClick={routeToNumberList}
          >
            <RiAddLine className='mr-2 h-5 w-5' />
            <span className='text-sm leading-none font-manrope font-medium'>Assign a number</span>
          </button>
        )}
      </div>
    </>
  );
};

export default NumbersNotFound;
