import { gql } from '@apollo/client';

export const PAGE_INFO = gql`
  fragment PageInfo on PageInfo {
    startCursor
    endCursor
    hasNextPage
    hasPreviousPage
    totalCount
  }
`;
