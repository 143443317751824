import styled from 'styled-components';

export const SettingsMenu = styled.div`
  padding: 20px 16px;
  width: 240px;
  z-index: 99;
  flex-shrink: 0;
  position: relative;
  box-shadow: 1px 0px 0px #e7e6eb !important;
  display: flex;
  flex-direction: column;
  &,
  & ~ div {
    overflow-y: auto;
  }
  & ~ div {
    width: calc(100vw - 60px - 240px);
    @media only screen and (max-width: 768px) {
      width: calc(100% - 240px);
    }
    // window - workspace sidebar width - settings sidebar width
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  .sticky-header {
    padding-top: 19px;

    @media only screen and (min-width: 1200px) {
      padding-top: 0;
    }
    @media only screen and (max-width: 1199px) {
      h6 {
        padding-left: 1.75rem;
      }
    }
  }
  .ant-menu {
    border-right: none;
    .ant-menu-item-group-title {
      font-family: 'Manrope', sans-serif;
      font-weight: 600;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.03em;
      color: #857f96;
      padding: 10px 10px 12px;
    }

    .ant-menu-item-selected {
      background-color: transparent;
      color: #613494;
      &::after {
        border-right: none !important;
        transition: none;
      }
    }

    .ant-menu-item-group {
      margin-top: 0px;
    }

    .ant-menu-item-group-list {
      .ant-menu-item {
        height: 36px;
        padding: 9px 10px 9px 14px !important;
        border-radius: 8px;
        margin: 0px;
        display: flex;
        align-items: center;
        .ant-menu-item-icon + span {
          margin-left: 12px;
        }
        svg {
          height: 18px;
          width: 18px;
          color: #6e6681;
        }
        &:hover {
          background: rgba(243, 242, 244, 0.5);
        }
        &::after {
          border-right: none !important;
          transition: none;
        }
        .ant-menu-title-content {
          line-height: 36px;
        }
      }

      .ant-menu-item-selected {
        background: #f3f2f4;
        h6,
        svg {
          color: #613494 !important;
        }
      }
    }

    &.cancel-subscription {
      .ant-menu-item {
        &:not(.billings-and-plans) {
          a,
          svg {
            color: ${({ theme }) => theme.colors.text.t3};
          }
        }
      }
    }
  }
  &.has-no-group {
    .ant-menu-item-selected {
      background: #f5f2f8;
      h6 {
        color: #613494;
      }
    }
    .ant-menu-item {
      padding: 9px 14px !important;
      border-radius: 8px;
      &::after {
        border-right: none !important;
        transition: none;
      }
      .ant-menu-item-icon + span {
        margin-left: 12px;
      }
    }
  }
`;
