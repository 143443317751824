import { useState } from 'react';

import {
  BookmarkPlusFill,
  CallTransferFill,
  KeypadIcon,
  MessageDotsRoundFill,
  MicrophoneFill,
  MicrophoneMuteFill,
  ThreeDotsIcon,
  NoteTextFill,
  PauseFill,
  RecordIcon,
  SpeakerIcon,
  UserSquareFill,
  PlayFill,
  DeafenedIcon,
  PhoneFill,
} from 'components/atoms/icon';
import { useTwilioContext } from 'lib/twilio';
import { CALL_WIDGET_STATUS } from 'lib/twilio/constants';
import { featureList, useFeatureByPlan } from 'hooks/useFeatureByPlan';

import { useCallActionStates } from './useCallActionStates';
import { useQuickSms } from './useQuickSms';
import { ICallActionProps } from '../call-action';

import useActiveCallWidgetContext from '../../hooks/useActiveCallWidgetContext';
import useCallWidgetContext from '../../hooks/useCallWidgetContext';
import useCallWidgetDrawerContext from '../../hooks/useCallWidgetDrawerContext';
import { BannerType } from '../../types';
import { CALL_ACTIVE_SCREENS } from '../../constants';

export const useCallActions = () => {
  const { ADD_TAG, ADD_NOTE, ADD_CONTACT, KEYPAD, CALL_TRANSFER } = CALL_ACTIVE_SCREENS;
  const [hasCallRecordAccess] = useFeatureByPlan(featureList['call-recordings-and-storage']);
  const [hasCallTransferAccess] = useFeatureByPlan(featureList['call-transfer']);
  const {
    state: { status = 'initiated', callInProgress, connection, salesDialerWidget },
  } = useTwilioContext();
  const isTransferredCall = connection?.customParameters?.get('after_transfer') === 'True';
  const { showAlertBanner, setBanner } = useCallWidgetContext();
  const { setSelectedDrawer } = useCallWidgetDrawerContext();

  const { disableEndCall } = useCallActionStates();

  const {
    mute,
    isSpeakerMute,
    recording,
    hold,
    loadingCallRecord,
    autoRecordingEnabled,
    loadingCallHold,
    handleToggleMute,
    handleToggleMuteSpeaker,
    handleToggleRecording,
    handleToggleHold,
    callDurationTime,
  } = useActiveCallWidgetContext();

  const [showMoreActions, setShowMoreActions] = useState(false);

  const showDrawerWithOngoingCallBar = (action: string) => () => {
    setSelectedDrawer(action);
    setBanner?.({
      title: `Ongoing Call - ${callDurationTime}`,
      closable: false,
      type: BannerType.success,
      icon: <PhoneFill className='w-4.5 h-4.5' />,
      showIcon: true,
      alignContent: 'center',
    });
  };

  const handleTransferClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!hasCallTransferAccess) {
      showAlertBanner?.('This is a premium feature.', BannerType.info);
      return;
    }
    showDrawerWithOngoingCallBar(CALL_TRANSFER)?.();
  };

  const { handleOpenConversation } = useQuickSms();

  const INITIAL_CALL_ACTIONS: ICallActionProps[] = [
    {
      label: recording ? 'Recording' : 'Record',
      icon: <RecordIcon />,
      variant: recording ? 'danger' : 'primary',
      onClick: handleToggleRecording,
      disabled:
        !hasCallRecordAccess ||
        !callInProgress ||
        loadingCallRecord ||
        isTransferredCall ||
        !autoRecordingEnabled,
    },
    {
      label: !mute ? 'Microphone' : 'Muted',
      icon: !mute ? <MicrophoneFill /> : <MicrophoneMuteFill />,
      variant: !mute ? 'primary' : 'danger',
      onClick: handleToggleMute,
      disabled: !callInProgress,
    },
    // new feature: speaker
    {
      label: !isSpeakerMute ? 'Speaker' : 'Deafened',
      icon: !isSpeakerMute ? <SpeakerIcon /> : <DeafenedIcon />,
      variant: !isSpeakerMute ? 'primary' : 'danger',
      onClick: handleToggleMuteSpeaker,
      disabled: !callInProgress,
    },
    {
      label: hold ? 'Resume' : 'Hold',
      icon: hold ? <PlayFill /> : <PauseFill />,
      variant: hold ? 'info' : 'primary',
      onClick: handleToggleHold,
      disabled: !callInProgress || loadingCallHold,
    },
    {
      label: 'Keypad',
      icon: <KeypadIcon />,
      onClick: showDrawerWithOngoingCallBar(KEYPAD),
      disabled: !callInProgress,
    },
    {
      label: 'More',
      icon: <ThreeDotsIcon />,
      onClick: () => setShowMoreActions(true),
    },
  ];

  const MORE_CALL_ACTIONS: ICallActionProps[] = [
    {
      label: 'Add Tag',
      icon: <BookmarkPlusFill />,
      onClick: showDrawerWithOngoingCallBar(ADD_TAG),
    },
    {
      label: 'Add Note',
      icon: <NoteTextFill />,
      onClick: showDrawerWithOngoingCallBar(ADD_NOTE),
    },
    {
      label: 'Add Contact',
      icon: <UserSquareFill />,
      onClick: showDrawerWithOngoingCallBar(ADD_CONTACT),
    },
    {
      label: 'Quick SMS',
      icon: <MessageDotsRoundFill />,
      onClick: handleOpenConversation,
    },
    {
      label: 'Call Transfer',
      icon: <CallTransferFill />,
      disabled:
        !hasCallTransferAccess ||
        !callInProgress ||
        !!hold ||
        isTransferredCall ||
        status === CALL_WIDGET_STATUS.TRANSFERRING,
      onClick: handleTransferClick,
    },
    {
      label: 'Less',
      icon: <ThreeDotsIcon />,
      onClick: () => setShowMoreActions(false),
    },
  ];

  const actionsToShow = showMoreActions ? MORE_CALL_ACTIONS : INITIAL_CALL_ACTIONS;

  return {
    actionsToShow,
    disableEndCall,
  };
};
