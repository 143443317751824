import { createContext } from 'react';

import { useActiveCallWidgetHandler } from '../hooks/useActiveCallWidgetHandler';

export const ActiveCallWidgetContext = createContext({});

export const ActiveCallWidgetProvider = ({ children }: any) => {
  const {
    callDurationTime,
    mute,
    isSpeakerMute,
    recording,
    recordingTime,
    loadingCallRecord,
    autoRecordingEnabled,
    hold,
    loadingCallHold,
    clientInfo,
    channelInfo,
    keypadNumberInput,
    note,
    loadingSaveNote,
    loadingCallTransfer,
    selectedMoreOption,
    loadingCampaignSaveNote,
    campaignCallNote,
    handleCallAnswer,
    handleCallReject,
    handleToggleRecording,
    handleToggleHold,
    handleToggleMute,
    handleToggleMuteSpeaker,
    handleDialKeypad,
    handleNoteSave,
    handleCallTransfer,
    handleSelectMoreAction,
    handleExecuteCampaignAction,
    handleCampaignNoteSave,
    handleSkipCampaignCallQueue,
    handleResetCallWidget,
    handleDropVoicemail,
    removeTrailingKeypadNumber,
  } = useActiveCallWidgetHandler();

  return (
    <ActiveCallWidgetContext.Provider
      value={{
        callDurationTime,
        mute,
        isSpeakerMute,
        recording,
        recordingTime,
        loadingCallRecord,
        autoRecordingEnabled,
        hold,
        loadingCallHold,
        clientInfo,
        channelInfo,
        keypadNumberInput,
        note,
        loadingSaveNote,
        loadingCallTransfer,
        selectedMoreOption,
        loadingCampaignSaveNote,
        campaignCallNote,
        handleCallAnswer,
        handleCallReject,
        handleToggleRecording,
        handleToggleHold,
        handleToggleMute,
        handleToggleMuteSpeaker,
        handleDialKeypad,
        handleNoteSave,
        handleCallTransfer,
        handleSelectMoreAction,
        handleExecuteCampaignAction,
        handleCampaignNoteSave,
        handleSkipCampaignCallQueue,
        handleResetCallWidget,
        handleDropVoicemail,
        removeTrailingKeypadNumber,
      }}
    >
      {children}
    </ActiveCallWidgetContext.Provider>
  );
};
