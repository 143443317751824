import { ACTIONS, CALL_WIDGET_STATUS, CAMPAIGN_STATUS } from '../constants';

export default function callsReducer(state: any, action: any) {
  switch (action.type) {
    case ACTIONS.SHOW_DIALER:
      return {
        ...state,
        direction: '',
        callInProgress: false,
        callEnded: false,
        status: CALL_WIDGET_STATUS.INITIATE,
        showPhoneWidget: true,
        showDialer: true,
        clientNumber: action.data?.contactNumber,
        salesDialerWidget: false,
      };
    case ACTIONS.INCOMING_CALL:
      return {
        ...state,
        direction: 'Incoming',
        callInProgress: false,
        connection: action.data,
        showPhoneWidget: true,
        callEnded: false,
        status: CALL_WIDGET_STATUS.INITIATED,
        channelId: action.channelId,
        showDialer: false,
        salesDialerWidget: false,
      };
    case ACTIONS.ANSWER_INCOMING_CALL:
      return { ...state, callInProgress: true, status: CALL_WIDGET_STATUS.ANSWERED };
    case ACTIONS.REJECT_INCOMING_CALL:
      return {
        ...state,
        callInProgress: false,
        conversationStatus: action.updateConversationStatus, // need to refactor
        callEnded: true,
        status: CALL_WIDGET_STATUS.REJECTED,
        showPhoneWidget: false,
        showDialer: false,
      };
    case ACTIONS.INCOMING_CALL_DISCONNECT:
      return {
        ...state,
        callInProgress: false,
        callEnded: true,
        status: CALL_WIDGET_STATUS.DISCONNECTED,
        showPhoneWidget: false,
        showDialer: false,
      };
    case ACTIONS.INCOMING_CALL_CANCEL:
    case ACTIONS.INCOMING_CALL_ERROR:
      return {
        ...state,
        callInProgress: false,
        status: CALL_WIDGET_STATUS.FAILED,
        showPhoneWidget: false,
        showDialer: false,
      };
    case ACTIONS.OUTGOING_CALL_ENABLED:
      return {
        ...state,
        direction: 'Outgoing',
        callInProgress: false,
        callEnded: false,
        showPhoneWidget: true,
        showDialer: false,
        salesDialerWidget: false,
        outgoingCallParams: action.callParams,
      };
    case ACTIONS.OUTGOING_CALL_INITIATED:
      return {
        ...state,
        status: CALL_WIDGET_STATUS.INITIATED,
        connection: action.data,
        channelId: action.channelId,
      };
    case ACTIONS.OUTGOING_CALL_RINGING:
      return {
        ...state,
        status: 'ringing',
        showPhoneWidget: true,
        showDialer: false,
      };
    case ACTIONS.OUTGOING_CALL_ANSWERED:
      return {
        ...state,
        status: 'answered',
        callInProgress: true,
        showPhoneWidget: true,
        showDialer: false,
      };
    case ACTIONS.OUTGOING_CALL_REJECTED:
      return {
        ...state,
        callInProgress: false,
        status: 'rejected',
        callEnded: true,
        showPhoneWidget: false,
        showDialer: false,
        outgoingCallParams: {},
      };
    case ACTIONS.OUTGOING_CALL_DISCONNECT:
      return {
        ...state,
        callInProgress: false,
        status: 'disconnected',
        callEnded: true,
        showPhoneWidget: false,
        showDialer: false,
        outgoingCallParams: {},
      };
    case ACTIONS.CALL_TRANSFER_INITIAED:
    case ACTIONS.CALL_TRANSFER_INPROGRESS:
      return {
        ...state,
        status: CALL_WIDGET_STATUS.TRANSFERRING,
        transferTo: action.data?.transferTo,
      };
    case ACTIONS.CALL_TRANSFER_SUCCESS:
      return {
        ...state,
        status: CALL_WIDGET_STATUS.TRANSFER_SUCCESS,
      };
    case ACTIONS.CALL_TRANSFER_FAILED:
      return {
        ...state,
        status: CALL_WIDGET_STATUS.TRANSFER_FAILED,
        transferTo: action.data?.transferTo,
      };
    case ACTIONS.CALL_ENDED:
      return {
        callEnded: true,
        callInProgress: false,
        status: CALL_WIDGET_STATUS.ENDED,
      };
    case ACTIONS.CLOSE_PHONE_WIDGET:
      return {
        showPhoneWidget: false,
        showDialer: false,
        callEnded: false,
        status: CALL_WIDGET_STATUS.ENDED,
      };
    case ACTIONS.SET_LIVECALL:
      return {
        ...state,
        liveCall: action.data,
      };
    case ACTIONS.CAMPAIGN_CALL_INITATED:
      return {
        ...state,
        direction: 'Outgoing',
        callInProgress: true,
        connection: action.data,
        showPhoneWidget: true,
        callEnded: false,
        status: CALL_WIDGET_STATUS.ANSWERED,
        channelId: action.channelId,
        showDialer: false,
        salesDialerWidget: true,
        campaignStatus: CAMPAIGN_STATUS.ACTIVE,
        voicemailDropEnabled: false,
        prevCallSkipped: false, // reset value
      };
    case ACTIONS.CAMPAIGN_CALL_CONNECTED:
      return {
        ...state,
        voicemailDropEnabled: action.data?.voicemailDropEnabled,
      };
    case ACTIONS.CAMPAIGN_CALL_ENDED:
      return {
        ...state,
        direction: 'Outgoing',
        callInProgress: false,
        connection: action.data,
        showPhoneWidget: true,
        callEnded: true,
        status: CALL_WIDGET_STATUS.ENDED,
        showDialer: false,
        salesDialerWidget: true,
        campaignStatus: CAMPAIGN_STATUS.ENDED,
        voicemailDropEnabled: false,
        nextCallQueue: {}, // reset value
      };
    case ACTIONS.CAMPAIGN_CALL_DISCONNECTED: // state is same as call ended, only status is changed
      return {
        ...state,
        status: CALL_WIDGET_STATUS.CAMPAIGN_CALL_DISCONNECTED,
        campaignStatus: CAMPAIGN_STATUS.ENDED,
        callStatusMessage: action?.data,
      };
    case ACTIONS.CAMPAIGN_PAUSED:
      return {
        ...state,
        campaignStatus: CAMPAIGN_STATUS.PAUSED,
      };
    case ACTIONS.CAMPAIGN_INPROGRESS:
      return {
        ...state,
        campaignStatus: CAMPAIGN_STATUS.INPROGRESS,
      };
    case ACTIONS.CAMPAIGN_STOPPED:
    case ACTIONS.CAMPAIGN_COMPLETED:
      return {
        ...state,
        direction: 'Outgoing',
        callInProgress: false,
        connection: {},
        showPhoneWidget: false,
        callEnded: false,
        status: CALL_WIDGET_STATUS.ENDED,
        showDialer: false,
        salesDialerWidget: false,
        campaignStatus: CAMPAIGN_STATUS.ENDED,
        nextCallQueue: {}, // reset value
        prevCallSkipped: false, // reset value
        prevConnection: action.data || state.connection,
      };
    case ACTIONS.CAMPAIGN_CALL_SKIPPED:
      return {
        ...state,
        connection: action.data.connection,
        nextCallQueue: action.data.nextCallQueue,
        prevCallSkipped: true, // Detect if the user has skipped the prev call
        nextQueueAfterSkip: action.data.nextCallQueue?.number, // next queue number after skip click
      };
    case ACTIONS.CLOSE_SALES_DIALER_WIDGET: // close power dialer
      return {
        ...state,
        callInProgress: false,
        showPhoneWidget: false,
        showDialer: false,
        salesDialerWidget: false,
      };
    default:
      throw new Error();
  }
}
