import { ReactNode } from 'react';
import { RiCloseLine } from 'react-icons/ri';

import { StyledAlert } from './Styles';

export interface IWidgetBanner {
  title?: string;
  type?: 'info' | 'error' | 'warning' | 'success';
  icon?: ReactNode;
  showIcon?: boolean;
  closable?: boolean;
  onClose?: (e: any) => void;
  alignContent?: 'left' | 'center';
}

const WidgetBanner = ({
  title,
  type,
  icon,
  showIcon = false,
  closable = false,
  onClose,
  alignContent = 'left',
}: IWidgetBanner) => {
  return (
    <StyledAlert
      message={title}
      type={type}
      icon={icon}
      closeIcon={<RiCloseLine />}
      showIcon={showIcon}
      closable={closable}
      onClose={onClose}
      className={`${alignContent}`}
    />
  );
};

export default WidgetBanner;
