import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from 'graphql/fragments/error';
import { PAGE_INFO } from 'graphql/fragments/pagination';

import { TAG_NODE, CONTACT_NODE, CLIENT, CLIENT_INFO } from './fragments';

export const GET_ALL_CONTACTS = gql`
  query newContacts($tags: [ShortId]) {
    newContacts(tags: $tags) {
      status
      error {
        ...Error
      }
      data {
        id
        email
        name
        profilePicture
        createdBy
        number
        tags {
          ...TagFields
        }
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${TAG_NODE}
`;
export const GET_BLOCKED_CONTACTS = gql`
  query blockedContacts {
    blockedContacts {
      status
      error {
        ...Error
      }
      data {
        id
        email
        name
        number
        profilePicture
        createdBy
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
export const CONTACTS = gql`
  query contacts($params: ConnectionInput, $tags: [ShortId]) {
    contacts(params: $params, tags: $tags) {
      status
      error {
        ...Error
      }
      data {
        pageInfo {
          ...PageInfo
        }
        edges {
          cursor
          node {
            id
            email
            name
            profilePicture
            number
            tags {
              ...TagFields
            }
          }
        }
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${PAGE_INFO}
  ${TAG_NODE}
`;

export const CONTACT = gql`
  query contact($id: ShortId!) {
    contact(id: $id) {
      status
      error {
        ...Error
      }
      data {
        ...ContactFields
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${CONTACT_NODE}
`;

export const GET_CLIENT_INFO_BY_PHONE = gql`
  query clientDetail($number: String!) {
    clientDetail(number: $number) {
      status
      error {
        ...Error
      }
      data {
        ...ClientFields
      }
    }
  }
  ${CLIENT}
  ${ERROR_FRAGMENT}
`;

export const CLIENT_NOTES = gql`
  query clientNotes($contact: String!, $channel: ShortId!) {
    clientNotes(contact: $contact, channel: $channel) {
      status
      error {
        ...Error
      }
      data {
        id
        title
        createdAt
        userId
        firstName
        lastName
        profilePicture
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const ALL_TAGS = gql`
  query allTags {
    allTags {
      status
      error {
        ...Error
      }
      data {
        ...TagFields
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${TAG_NODE}
`;

export const GET_AUTO_REPLY_TEXT = gql`
  query autoReplyData($id: ShortId!) {
    autoReplyData(id: $id) {
      status
      error {
        ...Error
      }
      data {
        status
        message
        channelId
        autoreplyType
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const GET_GREETINGS_RECORDING = gql`
  query greetingsList($channel: ShortId!, $greetingType: GreetingType) {
    greetings(channel: $channel, greetingType: $greetingType) {
      status
      error {
        ...Error
      }
      data {
        id
        recordingUrl
        greetingType
        recordingType
        source
        voice
        accent
        status
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const LAST_CONTACTED = gql`
  query lastContactedTime($contact: String!) {
    lastContactedTime(contact: $contact) {
      status
      error {
        ...Error
      }
      data {
        createdAt
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const GET_CONTACTS_LOGS = gql`
  query getContactsLogs($params: ConnectionInput) {
    getContactsLogs(params: $params) {
      status
      error {
        ...Error
      }
      data {
        pageInfo {
          ...PageInfo
        }
        edges {
          cursor
          node {
            clientNumber
            clientInfo {
              ...ClientInfoFields
            }
          }
        }
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${PAGE_INFO}
  ${CLIENT_INFO}
`;
