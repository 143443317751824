import { useContext, useEffect, useState } from 'react';

import { useTwilioContext } from 'lib/twilio';
import { CALL_WIDGET_STATUS } from 'lib/twilio/constants';
import { AuthContext } from 'contexts/auth/AuthContext';
import { Icon } from 'components/atoms';
import { nameElipsis } from 'components/utils/helpers';

import useCallWidgetContext from '../../hooks/useCallWidgetContext';
import { BannerType } from '../../types';
import { KYC_UNVERIFIED_MESSAGE } from '../../constants';

export const useCallActionStates = () => {
  const { isKYCVerified } = useContext(AuthContext);
  const [disableEndCall, setDisableEndCall] = useState<boolean>(true);
  const [showResume, setShowResume] = useState(false);
  const NAME_CHAR_LIMIT = 10;
  const {
    state: { status = 'initiated', transferTo },
  } = useTwilioContext();
  const { showAlertBanner, setBanner } = useCallWidgetContext();

  useEffect(() => {
    if (status === CALL_WIDGET_STATUS.ANSWERED) {
      setTimeout(() => {
        setDisableEndCall(false);
      }, 5000);
    }
  }, [status]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (status === CALL_WIDGET_STATUS.TRANSFER_FAILED) {
      setShowResume(true);
      const timer = setTimeout(() => {
        setShowResume(false);
      }, 3000);
      return () => clearInterval(timer);
    }
  }, [status]);

  useEffect(() => {
    if (status === CALL_WIDGET_STATUS.TRANSFERRING && transferTo) {
      setBanner?.({
        title: `Call transferring to (${nameElipsis(transferTo, NAME_CHAR_LIMIT)})`,
        closable: true,
        type: BannerType.info,
        icon: <Icon name='call-forwarded' />,
        showIcon: true,
      });
      return;
    }
    if (status === CALL_WIDGET_STATUS.TRANSFER_FAILED && transferTo) {
      showAlertBanner?.(`Call transfer to ${nameElipsis(transferTo, NAME_CHAR_LIMIT)} Failed`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, transferTo]);

  useEffect(() => {
    if (!isKYCVerified) {
      setBanner?.({
        title: KYC_UNVERIFIED_MESSAGE,
        closable: true,
        type: BannerType.warning,
        icon: <Icon name='exclamation-triangle' />,
        showIcon: true,
      });
    }
  }, [isKYCVerified, setBanner]);

  return {
    disableEndCall,
    setShowResume,
  };
};
