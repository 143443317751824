import { Collapse } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import ScrollBar from 'react-perfect-scrollbar';
import { RiArrowDownSLine } from 'react-icons/ri';
import { yupResolver } from '@hookform/resolvers/yup';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useForm, FormProvider } from 'react-hook-form';

import { CLIENT_NOTES } from 'graphql/client';
import { pick } from 'components/utils/helpers';
import { ContactNode, Tag, Maybe } from 'generated/graphql';
import AddActionButton from 'components/molecules/button/AddActionButton';

import * as S from './Styles';
import { setTag } from './utils';
import ClientNotes from './ClientNotes';
import ContactDetailHeader from './header';
import TagsDetailForm from './form/TagsDetailForm';
import { ContactDetailForm } from './form/ContactDetailForm';
import { ContactDetailContext } from './ContactDetailContext';
import useContactEditHandlers from './hooks/useContactEditHandlers';

import { schema } from '../schema';

const { Panel } = Collapse;
const CONTACT_DETAILS_PANEL_KEY = 'contact-details';
const TAGS_PANEL_KEY = 'tags';
const CLIENT_NOTES_PANEL_KEY = 'client-notes';

export default function ContactDetail(props: { data: ContactNode }) {
  const { data } = props;
  const contactNumber = data?.number;
  const { channelId, contactId } = useParams<Record<string, string | undefined>>();
  const [isTagPanelOpen, setIsTagPanelOpen] = useState<boolean>();
  const [isNotesPanelOpen, setIsNotesPanelOpen] = useState<boolean>(true);

  const [loadClientNotes, { data: notes }] = useLazyQuery(CLIENT_NOTES, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (contactNumber && channelId) {
      loadClientNotes({
        variables: {
          contact: contactNumber,
          channel: channelId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactNumber, loadClientNotes]);

  const numberOfNotes = notes?.clientNotes?.data?.length || 0;

  const methods = useForm<any>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      email: '',
      number: '',
      address: '',
      company: '',
      tags: [],
    },
  });
  const {
    handleEnterSubmit,
    handleSelectChange,
    handleEditableChange,
    handleTagChange,
    handleTagCreate,
    handleOnTagBlur,
    handleColorPicking,
    handleSaveContactName,
    isTagFieldLoading,
    tagSelectRef,
    tagFieldOptions,
    showTagColorOptions,
    editableField,
    setTagFieldValue,
    tagFieldValue,
    notesFieldRef,
    resetTagsField,
  } = useContactEditHandlers({ data, methods });

  useEffect(() => {
    if (data) {
      const contactDetails = pick(data, 'name', 'email', 'number', 'address', 'company');
      Object.entries(contactDetails).forEach(([key, value]) => {
        methods.setValue(key as any, value);
      });
      methods.setValue('tags', data?.tags);
      if (data?.tags) {
        const tagValue = data?.tags.map((tag: Maybe<Tag>) => ({
          ...tag,
          ...setTag(tag as Tag),
        }));
        setTagFieldValue(tagValue);
      }
    }
    return () => {
      methods.clearErrors();
      methods.reset({});
      resetTagsField();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, setTagFieldValue, contactId]);
  const { t } = useTranslation();

  const handleCollapseChange = (openedPanelList: string | string[]) => {
    if (openedPanelList.includes(TAGS_PANEL_KEY)) setIsTagPanelOpen(true);
    else setIsTagPanelOpen(false);
    if (openedPanelList.includes(CLIENT_NOTES_PANEL_KEY)) setIsNotesPanelOpen(true);
    else setIsNotesPanelOpen(false);
  };

  const handleAddTagFromPanel = (e: React.MouseEvent<HTMLElement>) => {
    if (isTagPanelOpen) e.stopPropagation();

    setTimeout(() => {
      if (tagSelectRef) {
        tagSelectRef?.current.focus();
      }
    }, 1);
  };
  const handleAddNotesFromPanel = (e: React.MouseEvent<HTMLElement>) => {
    if (isNotesPanelOpen) e.stopPropagation();
    setTimeout(() => {
      if (notesFieldRef) {
        notesFieldRef?.current.focus();
      }
    }, 1);
  };

  return (
    <ContactDetailContext.Provider
      value={{
        handleEnterSubmit,
        handleSelectChange,
        handleEditableChange,
        handleTagChange,
        handleTagCreate,
        handleOnTagBlur,
        handleColorPicking,
        handleSaveContactName,
        isTagFieldLoading,
        tagSelectRef,
        tagFieldOptions,
        showTagColorOptions,
        editableField,
        tagFieldValue,
        setTagFieldValue,
        notesFieldRef,
      }}
    >
      <FormProvider {...methods}>
        <div className='w-90 flex-shrink-0 h-full' data-cy='contacts-details-wrap'>
          <ScrollBar>
            <ContactDetailHeader />
            <S.CollapseWrapper data-cy='collapse'>
              <Collapse
                defaultActiveKey={[CONTACT_DETAILS_PANEL_KEY, CLIENT_NOTES_PANEL_KEY]}
                expandIcon={({ isActive }) => (
                  <RiArrowDownSLine className={`rotate--${isActive ? '0' : '90'}`} />
                )}
                ghost
                onChange={handleCollapseChange}
              >
                <Panel
                  header={t('panel.header.tags', 'Tags')}
                  key={TAGS_PANEL_KEY}
                  extra={<AddActionButton onClick={handleAddTagFromPanel} />}
                  className='tags-panel'
                >
                  <TagsDetailForm />
                </Panel>
                <Panel
                  data-cy='contacts-details'
                  header={t('panel.header.details', 'Details')}
                  key={CONTACT_DETAILS_PANEL_KEY}
                  className='content-padding'
                >
                  <ContactDetailForm />
                </Panel>
                <div className='mt-4.5 -mx-5 mb-2 border-b border-gray-100 border-solid' />

                {channelId && (
                  <Panel
                    header={
                      <div className='notes-collapse-header'>
                        {t('panel.header.notes', 'Notes')} <span>{numberOfNotes}</span>
                      </div>
                    }
                    key={CLIENT_NOTES_PANEL_KEY}
                    extra={<AddActionButton onClick={handleAddNotesFromPanel} />}
                  >
                    <ClientNotes
                      contactNumber={data?.number}
                      channelId={channelId}
                      notes={notes?.clientNotes?.data}
                    />
                  </Panel>
                )}
              </Collapse>
            </S.CollapseWrapper>
          </ScrollBar>
        </div>
      </FormProvider>
    </ContactDetailContext.Provider>
  );
}
