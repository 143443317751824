import { useContext, useEffect } from 'react';
import { Menu } from 'antd';
import { format, getUnixTime } from 'date-fns';
import { RiQuestionLine } from 'react-icons/ri';
import { HiOutlineChevronRight } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';

import { ActionsModal } from 'components/organisms';
import { Tooltip } from 'components/atoms';
import { ManIcon, LogoutIcon, DotIcon, LogoutSvg } from 'components/atoms/icon';
import { getDNDvalue } from 'components/utils/getDndValue';
import { MoonNight } from 'components/atoms/vectors';
import cache from 'services/apollo/cache';
import { Query } from 'generated/graphql';
import { GET_USER_PROFILE } from 'graphql/user';
import { nameElipsis } from 'components/utils/helpers';
import ToolTip from 'components/atoms/tooltip/Tooltip';
import { BadgedAvatar, UserAvatar } from 'components/atoms/avatar';
import { AuthContext } from 'contexts/auth/AuthContext';

import * as S from './Styles';
import useUserSettings from './useUserSettings';
import useDND from './useDND';

const { SubMenu } = Menu;

export default function UserSettings() {
  const { isSubscriptionExpired } = useContext(AuthContext);
  const { t } = useTranslation();
  const {
    onLogout,
    loggingOut,
    handleProfileEdit,
    redirectToLanguagePage,
    firstname,
    lastname,
    profilePicture,
    visibleLogoutModal,
    isUserOnline,
    toggleLogoutModal,
  } = useUserSettings();
  const { selectDND } = useDND();

  const cachedData: Pick<Query, 'profile'> | null = cache.readQuery({
    query: GET_USER_PROFILE,
  });
  const { dndEnabled, dndEndtime, dndDuration } = cachedData?.profile?.data || {};

  const DND_OPTIONS = [
    { label: t('dnd.30min', 'For 30 minutes'), value: 'THIRTY_MINUTES' },
    { label: t('dnd.1hr', 'For 1 hour'), value: 'ONE_HOUR' },
    { label: t('dnd.8hr', 'For 8 hours'), value: 'EIGHT_HOURS' },
    { label: t('dnd.untilTurnedBackOn', 'Until I turn it back on'), value: 'UNTIL_I_RESUME' },
  ];

  const DND_VALUES = [...DND_OPTIONS?.map(option => option.value), 'OFF'];

  useEffect(() => {
    let timerId: ReturnType<typeof setInterval>;
    if (dndEnabled && !!dndEndtime) {
      timerId = setInterval(() => {
        const currentTime = getUnixTime(new Date());
        if (dndEndtime <= currentTime) {
          selectDND('OFF');
          clearInterval(timerId);
        }
      }, 20000);
    }
    return () => clearInterval(timerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cachedData?.profile?.data]);

  const handleMenuClick = ({ key }: any) => {
    if (DND_VALUES?.includes(key)) {
      selectDND(key);
    }
  };

  const memberFullName = `${firstname} ${lastname}`;

  const NAME_CHAR_LIMIT = 18;
  const showNameTooltip = memberFullName.length > NAME_CHAR_LIMIT;

  const subMenuItems = (
    <>
      <h6
        data-cy='set-away'
        className='flex items-center text-13 font-semibold leading-4 text-gray-500 justify-between px-4 py-2.25 min-w-230 cy-more-set-option'
      >
        Set yourself away
        <span data-cy='set-away-tooltip'>
          <ToolTip title='This allows you to set yourself away'>
            <RiQuestionLine className='text-gray-500 h-3.25 w-3.25' />
          </ToolTip>
        </span>
      </h6>

      {DND_OPTIONS.map(option => (
        <Menu.Item
          key={option.value}
          className={
            option.value === getDNDvalue(dndDuration as number, dndEnabled as boolean)
              ? `active-dnd cy-more-set-option-${option.value}`
              : `cy-more-set-option-${option.value}`
          }
        >
          {option.label}
        </Menu.Item>
      ))}
    </>
  );

  const subMenuItemsOnDND = (
    <>
      <div className='bg-primary-600 p-3.5 flex items-center justify-between mb-2.5 -mt-2.5 rounded-t-10'>
        <div className='mr-4'>
          <h6 className='text-15 leading-5 text-white font-bold mb-1'>User set to Away</h6>
          <p className='text-13 leading-4 text-primary-100'>{`Notifications paused until ${
            dndEndtime ? format(new Date(dndEndtime * 1000), 'h:mm a') : 'I turn it off'
          }`}</p>
        </div>
        <div className='h-9 w-9'>
          <MoonNight />
        </div>
      </div>
      <Menu.Item className='resume cy-set-away-resume' key='OFF'>
        Resume Notifications
      </Menu.Item>
      <SubMenu title='Adjust time cy-adjust-time'>{subMenuItems}</SubMenu>
    </>
  );

  const menu = (
    <Menu className='w-60' onClick={handleMenuClick} expandIcon={<HiOutlineChevronRight />}>
      <div className='flex items-center w-full px-4 pt-0.75 pb-1.75'>
        <UserAvatar shape='square' size={36} icon={<ManIcon />} src={profilePicture} />
        <div className='ml-3'>
          <Tooltip title={showNameTooltip ? memberFullName : ''}>
            <h6
              data-cy='online-info-name'
              className='text-sm font-semibold leading-5 text-gray-600'
            >
              {nameElipsis(memberFullName ?? '', NAME_CHAR_LIMIT)}
            </h6>
          </Tooltip>
          <p
            className={`text-xs leading-4.5  flex items-center ${
              isUserOnline ? 'text-success' : 'text-gray'
            }`}
          >
            <DotIcon isActive={isUserOnline} />
            <span data-cy='online-info' className='ml-1'>
              {' '}
              {isUserOnline ? 'Active' : 'Offline'}
            </span>
          </p>
        </div>
      </div>
      <Menu.Divider />
      <SubMenu
        title={dndEnabled || dndEnabled == null ? 'Set yourself online' : 'Set yourself as away'}
        key='setAway'
      >
        {dndEnabled || dndEnabled == null ? subMenuItemsOnDND : subMenuItems}
      </SubMenu>

      <Menu.Item onClick={handleProfileEdit} key='edit-profile'>
        <span data-cy='online-info-edit'>{t('editProfile', 'Edit Profile')}</span>
      </Menu.Item>

      <Menu.Divider />

      <Menu.Item onClick={redirectToLanguagePage} key='language'>
        <span data-cy='online-info-lang'>Language</span>
      </Menu.Item>

      <Menu.Divider />

      <Menu.Item onClick={toggleLogoutModal} key='logout'>
        <div data-cy='online-info-sign' className='w-full flex items-center justify-between'>
          Sign Out
          <LogoutIcon />
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <S.CustomPopover
        content={menu}
        getPopupContainer={triggerNode => triggerNode as HTMLElement}
        placement='rightBottom'
        trigger={['click']}
        className='cursor-pointer cy-wks-avatar-side'
      >
        <div>
          <BadgedAvatar
            isOnline={isUserOnline}
            src={profilePicture}
            badgeOffset={[-5, 30]}
            dotSize={10}
            dotBoxShadowColor='#390179'
            dotBoxShadowWidth={3}
            isSubscriptionExpired={isSubscriptionExpired}
            avatarSize={36}
            avatarStyles={{ borderRadius: 14 }}
          />
        </div>
      </S.CustomPopover>
      <ActionsModal
        type='primary'
        icon={<LogoutSvg />}
        isModalOpen={visibleLogoutModal}
        onOk={onLogout}
        loading={loggingOut}
        onCancel={toggleLogoutModal}
        onModalCancel={toggleLogoutModal}
        title={t('modal.title.logoutConfirm', 'Confirm Sign out')}
        info={t('modal.info.logoutConfirm', 'Are you sure you want to sign out from KrispCall?')}
        btnText={t('modal.btnText.signout', 'Sign out')}
      />
    </>
  );
}
