export const WIDGET_WIDTH = 340;
export const WIDGET_HEIGHT = 554;

export const WIDGET_HEADER_HEIGHT = 54;
export const WIDGET_SIDEBAR_WIDTH = 44;

export const PHONE_INPUT_PADDING_RIGHT = 18;

// Dialer action keyword
export const CALL = 'call';
export const CLEAR = 'clear';

export const ERROR_MESSAGES = {
  numberNotAvailable: "You don't have any numbers on this workspace",
  invalidContactName: 'Choose an existing contact or dial',
  invalidPhoneNumber: 'Invalid phone number',
  emergencyCalls: 'For emergency calls contact support',
  noMicPermission: 'Permission needed for microphone',
  accountSuspended: 'Your KrispCall Account is currently suspended due to lack of credits',
  deletedWorkspace: 'Your current workspace is deleted',
  underReview: 'Your KrispCall account is under review, Please contact support!',
  subscriptionCancelled: 'Your KrispCall subscription has been cancelled',
  subscriptionExpired: 'Your KrispCall subscription has expired',
  intlCallMsgDisabled: 'International Calls and Messages Disabled',
  selfAssignedNumber: 'Number is assigned to one of your channel',
  callNotSupported: 'Call not supported',
  deviceOffline: 'Device is offline',
  numberSubscriptionExpired: 'Number subscription has been expired',
  creditInsufficient: 'Insufficient Credit',
  default: 'Something went wrong',
};

export const KYC_UNVERIFIED_MESSAGE = 'Your limit is 15 minutes. Update KYC.';

// dialer drawer screens
const [ADD_TAG, ADD_NOTE, ADD_CONTACT, KEYPAD, CALL_TRANSFER, CUSTOM_MESSAGE] = [
  'add-tag',
  'add-note',
  'add-contact',
  'keypad',
  'call-transfer',
  'custom-message',
];
export const CALL_ACTIVE_SCREENS = { ADD_TAG, ADD_NOTE, ADD_CONTACT, KEYPAD, CALL_TRANSFER };
export const CALL_INCOMING_SCREENS = { CUSTOM_MESSAGE };

// nav screens
const [CALL_WIDGET, AUDIO_SETTINGS] = ['call-widget', 'audio-settings'];
export const WIDGET_NAV_SCREENS = { CALL_WIDGET, AUDIO_SETTINGS };
