import { memo } from 'react';

import { TwilioProvider } from 'lib/twilio';
import { AuthProvider } from 'contexts/auth/AuthContext';
import { TeamsProvider } from 'components/pages/settings/team/context/TeamsContext';
import useMediaDevices from 'lib/twilio/hooks/useMediaDevices';
import MainLayout from './MainLayout';

function AppLayout({ children }: any) {
  // just to prompt media device selection on app load
  const { hasAudioInputDevices } = useMediaDevices();

  return (
    <AuthProvider>
      <TwilioProvider>
        <TeamsProvider>
          <MainLayout>{children}</MainLayout>
        </TeamsProvider>
      </TwilioProvider>
    </AuthProvider>
  );
}

export default memo(AppLayout);
