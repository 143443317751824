import React, { ReactElement, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';

import { useTwilioContext } from 'lib/twilio';
import { Button, Icon } from 'components/atoms';
import { CAMPAIGN_ACTIONS } from 'components/pages/sales-dialer/campaigns/constants';
import { TimerIcon, UsersIcon } from 'components/atoms/icon';
import { parsePhoneNumber } from 'components/utils/phone-lib';
import { CALL_WIDGET_STATUS } from 'lib/twilio/constants';
import { activeCallCampaignStatus } from 'services/apollo/reactiveVars';
import useCallWidgetContext from '../hooks/useCallWidgetContext';
import { useCoolOffTimer } from '../hooks/useCoolOffTimer';
import { CampaignWidgetContainer } from './styles';
import { BannerType } from '../types';

export default function CampaignWidget(): ReactElement {
  const {
    state: { connection, status, callEnded, voicemailDropEnabled },
  } = useTwilioContext();
  const activeCampaignStatus = useReactiveVar<any>(activeCallCampaignStatus);
  const campaignId = connection?.customParameters?.get('campaignId') || '';
  const coolOffPeriod = connection?.customParameters?.get('coolOffPeriod') || 10;
  const nextQueue = connection?.customParameters?.get('nextQueue') || '';
  const conversationId = connection?.customParameters?.get('campaignConversationId') || '';

  const { nationalNumber, flagUrl, country } = parsePhoneNumber(nextQueue);
  const {
    handleExecuteCampaignAction,
    handleSkipCampaignCallQueue,
    handleResetCallWidget,
    handleDropVoicemail,
    setBanner,
  } = useCallWidgetContext();
  const { seconds: coolOffTime, startCoolOffTimer, resetCoolOffTime } = useCoolOffTimer({
    time: coolOffPeriod,
  });
  const isCampaignPaused = activeCampaignStatus === 'PAUSED';
  useEffect(() => {
    if (callEnded) {
      startCoolOffTimer();
      return;
    }
    resetCoolOffTime(coolOffPeriod);
    handleResetCallWidget?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callEnded]);

  const handleClickDropVoicemail = () => {
    handleDropVoicemail?.(conversationId, campaignId);
  };

  const handleCampaignAction = () => {
    const action = isCampaignPaused ? CAMPAIGN_ACTIONS.resume : CAMPAIGN_ACTIONS.pause;
    handleExecuteCampaignAction?.(campaignId, action);
  };

  useEffect(() => {
    if (isCampaignPaused) {
      setBanner?.({
        title: 'Campaign has been paused',
        closable: true,
        type: BannerType.warning,
        icon: <Icon name='pause-line' />,
        showIcon: true,
      });
      return;
    }
    setBanner?.({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCampaignStatus]);

  const queueCallId = connection?.customParameters?.get('nextCampaignConversationId');

  const handleSkipQueueCall = () => {
    if (queueCallId) handleSkipCampaignCallQueue?.(queueCallId, campaignId);
  };

  return (
    <CampaignWidgetContainer className='bg-primary-50 px-8 py-5'>
      <Button
        className='w-full btn-success'
        icon={<Icon name='voicemail' />}
        onClick={handleClickDropVoicemail}
        disabled={!voicemailDropEnabled}
      >
        Voicemail Drop
      </Button>
      <div className='mt-6 mb-5'>
        <div className='flex border-b  border-gray-100 items-center pb-4'>
          <span className='flex flex-1 items-center'>
            <TimerIcon />
            <p className='text-gray-600 ml-1.5 mr-1 text-13 leading-3.5'>Cool-off:</p>
            <h3 className='text-gray-600 text-13 leading-4 font-semibold'>{coolOffTime} Sec</h3>
          </span>
          {/* Hide skip button because we couldn't skip the cooloff time */}
          {/* <button type='button' className='skip-btn'>
            Skip
          </button> */}
        </div>
        <div className='flex items-center pt-4'>
          <UsersIcon />
          <span className='flex flex-1 items-center'>
            <p className='text-gray-600 ml-1.5 mr-1 text-13 leading-3.5'>In a queue:</p>
            <h3 className='text-gray-600 text-13 leading-4 font-semibold'>
              {queueCallId ? nationalNumber : '-'}
            </h3>
            <img src={flagUrl} alt={country} title={country} className='ml-1.5' />
          </span>
          {nationalNumber && queueCallId && (
            <button type='button' className='skip-btn' onClick={handleSkipQueueCall}>
              Skip
            </button>
          )}
        </div>
      </div>
      <Button
        className={`w-full ${isCampaignPaused ? 'btn-success-outlined' : 'btn-white'}`}
        onClick={handleCampaignAction}
        disabled={status !== CALL_WIDGET_STATUS.ENDED}
      >
        {isCampaignPaused ? 'Resume' : 'Pause'} Campaign
      </Button>
    </CampaignWidgetContainer>
  );
}
