import { memo, useEffect } from 'react';

import { useTwilioContext } from 'lib/twilio';
import { CALL_WIDGET_STATUS } from 'lib/twilio/constants';
import { ToastMessage } from 'components/atoms';

import Dialer from '../../dialer';
import CallActive from '../../call-active';
import IncomingCall from '../../call-incoming';
import { ActiveCallWidgetProvider } from '../../context/ActiveCallWidgetContext';
import { DrawerBannerWrapper } from '../../DrawerBannerWrapper';

function CallWidget() {
  const {
    state: { showDialer, direction, status, callInProgress, salesDialerWidget, callStatusMessage },
  } = useTwilioContext();

  useEffect(() => {
    if (status === CALL_WIDGET_STATUS.CAMPAIGN_CALL_DISCONNECTED)
      ToastMessage({
        content: callStatusMessage,
        type: 'danger',
      });
  }, [status, callStatusMessage]);

  const activeCallClassName = !callInProgress ? 'pb-3.5' : 'content-end pb-10';

  return (
    <>
      {showDialer && (
        <DrawerBannerWrapper>
          <Dialer />
        </DrawerBannerWrapper>
      )}

      {!showDialer && direction && (
        <ActiveCallWidgetProvider>
          <DrawerBannerWrapper className={activeCallClassName}>
            {direction === 'Incoming' && !callInProgress && !salesDialerWidget ? (
              <IncomingCall />
            ) : (
              <CallActive />
            )}
          </DrawerBannerWrapper>
        </ActiveCallWidgetProvider>
      )}
    </>
  );
}

export default memo(CallWidget);
