import { useEffect, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { useHistory, useLocation } from 'react-router-dom';

import { ChevronDown, Multiply } from 'components/atoms/icon';
import { useTwilioContext } from 'lib/twilio';
import { ACTIONS, CALL_WIDGET_STATUS } from 'lib/twilio/constants';
import { parsePhoneNumber } from 'components/utils/phone-lib';
import { useActiveWorkspaceQuery } from 'hooks/useActiveWorkspaceQuery';
import useLocalStorage from 'hooks/useLocalStorage';
import * as path from 'constants/routes';
import useRouteChecker from 'components/pages/layouts/useRouteChecker';

import { Wrapper, ChannelSelectInput, ChannelDropdownHeader } from './Styles';
import { useMakeCall } from './useMakeCall';
import { NumberCard } from './NumberCard';

import { WIDGET_SIDEBAR_WIDTH } from '../constants';

const getChannelOption = (channel: any) => {
  const option = channel || {};
  const { flagUrl = '', phoneFormatIntl = '' } = parsePhoneNumber(option?.number);
  return {
    value: option?.id,
    label: <NumberCard number={phoneFormatIntl} name={option?.name} flagUrl={flagUrl} />,
  };
};

const WidgetHeader = () => {
  const { activeWorkspaceData, loadWorkspace } = useActiveWorkspaceQuery();
  const [activeWorkspaceId] = useLocalStorage('activeWorkspaceId', null);
  useEffect(() => {
    if (activeWorkspaceId) loadWorkspace();
  }, [activeWorkspaceId, loadWorkspace]);

  const { plan } = activeWorkspaceData?.workspace?.data || {};

  const [isNumberSelectDropdownOpen, setIsNumberSelectDropdownOpen] = useState(false);
  const {
    dispatch,
    state: { callInProgress, status },
  } = useTwilioContext();

  const { channels, selectedChannel, handleChannelSelect } = useMakeCall();

  const channelOptions = channels?.map((channel: any) => getChannelOption(channel));

  const handleClose = () => {
    dispatch({
      type: ACTIONS.CLOSE_PHONE_WIDGET,
      data: {},
    });
  };

  const history = useHistory();
  const { pathname } = useLocation();
  const { isDialerPage } = useRouteChecker({
    pathname,
  });
  const routeToCreditPage = () => {
    if (isDialerPage) {
      window.open(`${path.SETTINGS}${path.CREDIT}`, '_blank');
      return;
    }
    history.push(`${path.SETTINGS}${path.CREDIT}`);
  };

  return (
    <Wrapper className='flex'>
      <div
        style={{ width: WIDGET_SIDEBAR_WIDTH }}
        className='flex justify-center items-center cursor-pointer flex-shrink-0'
      >
        {!(callInProgress || status === CALL_WIDGET_STATUS.INITIATED) && (
          <button type='button' onClick={handleClose}>
            <Multiply className='icon-with-bg' />
          </button>
        )}
      </div>
      <div className='border-l border-extra-widgetBorder w-full'>
        <ChannelSelectInput
          name='select-dialing-number'
          onDropdownVisibleChange={open => setIsNumberSelectDropdownOpen(open)}
          dropdownRender={menu => (
            <>
              <ChannelDropdownHeader>
                <div>
                  <h3>Total Credit</h3>
                  <p>$ {plan?.currentCredit ?? ''} USD</p>
                </div>
                <button type='button' onClick={routeToCreditPage}>
                  Add Credit
                </button>
              </ChannelDropdownHeader>
              <h2>Select Number</h2>
              {menu}
            </>
          )}
          suffixIcon={
            <ChevronDown
              className={`transition-all transform ${
                isNumberSelectDropdownOpen ? 'rotate-180' : 'rotate-0'
              } icon-with-bg`}
            />
          }
          options={channelOptions}
          value={selectedChannel?.id}
          listHeight={384}
          menuItemSelectedIcon={
            <FaCheckCircle className='relative -left-4' size={24} color='#007AFF' />
          }
          onChange={handleChannelSelect}
        />
      </div>
    </Wrapper>
  );
};

export default WidgetHeader;
