import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Input } from 'components/molecules/fields';
import { KeypadIcon, TagCrossFill } from 'components/atoms/icon';

import { KeypadContainer } from './Styles';
import useActiveCallWidgetContext from '../../hooks/useActiveCallWidgetContext';
import useCallWidgetDrawerContext from '../../hooks/useCallWidgetDrawerContext';
import Keypad from '../../common/keypad';
import { KeypadButton } from '../../common/keypad/KeypadButton';

const ActiveCallKeypad = () => {
  const { closeDrawer } = useCallWidgetDrawerContext();
  const {
    handleDialKeypad,
    keypadNumberInput,
    removeTrailingKeypadNumber,
  } = useActiveCallWidgetContext();

  const phoneNumberInput = {
    number: yup.string().required('Phone is required'),
  };

  const schema = yup.object().shape({
    number: phoneNumberInput.number,
  });

  const {
    control,
    formState: { errors },
    setValue,
  } = useForm<any>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {},
  });

  useEffect(() => {
    if (keypadNumberInput) {
      setValue('number', keypadNumberInput);
    }
  }, [keypadNumberInput, setValue]);

  return (
    <KeypadContainer>
      <Input
        placeholder='Enter Digits'
        name='number'
        id='number'
        control={control}
        errors={errors}
        readOnly
      />
      <div className='px-10.5'>
        <Keypad handleKeypadClick={handleDialKeypad} />
        <div className='flex gap-4 justify-end mt-3'>
          <KeypadButton onClick={() => closeDrawer()}>
            <KeypadIcon className='w-7 h-7' />
          </KeypadButton>
          <KeypadButton variant='tertiary' onClick={() => removeTrailingKeypadNumber?.()}>
            <TagCrossFill className='w-7 h-7' />
          </KeypadButton>
        </div>
      </div>
    </KeypadContainer>
  );
};

export default ActiveCallKeypad;
