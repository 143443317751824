export const errorKeys = [
  {
    errorKey: 'email_exists',
    message: 'Email already exists',
    translationKey: 'error.emailAreadyExist',
  },
  {
    errorKey: 'phone_number_exists',
    message: 'Phone Number already exists',
    translationKey: 'error.phoneAreadyExist',
  },
  {
    errorKey: 'internal_server_error',
    message: 'Internal Server Error',
    translationKey: 'error.internalServerError',
  },
  {
    errorKey: 'invalid_input',
    message: 'Invalid Input',
    translationKey: 'error.invalidInput',
  },
  {
    errorKey: 'limit_exceed',
    message: 'The upload limit is 200 contacts at a time.',
    translationKey: 'error.contactUploadlimitExceed',
  },
  {
    errorKey: 'forbidden',
    message: 'Forbidden',
    translationKey: 'error.forbidden',
  },
  {
    errorKey: 'toggle_recharge_time_limit_exceed',
    message: 'Wait for 60 seconds before attempting again',
    translationKey: 'error.toggleRechargeLimitExceed',
  },
];
