import { useState, useEffect } from 'react';
import { Space, Radio, Select, RadioChangeEvent } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { SelectInput } from 'components/molecules/fields';
import { useChargebeeQuery } from 'lib/chargebee/hooks/useChargebeeQuery';

import PaymentMethodCard from './PaymentMethodCard';

interface PurchaseCreditInputs {
  amount: string;
  paymentID: string;
}
const schema = yup.object().shape({
  amount: yup.string().required('Required'),
  paymentID: yup.string().required('Required'),
});

export function stopPropagate(callback: () => void) {
  return (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    callback();
  };
}
export default function CreditForm({ onSubmit }: any) {
  const { t } = useTranslation();
  const { cards } = useChargebeeQuery();
  const [paymentMethodID, setPaymentMethodID] = useState(null);

  const formSubmit = async (payload: Record<string, string>) => {
    const payloads = {
      ...payload,
      amount: payload?.amount?.replace?.(/[\s+, $]/g, ''),
    };
    await onSubmit?.(payloads);
  };
  const renderCards = cards?.map((card: any) => {
    return <PaymentMethodCard card={card} key={card.id} />;
  });
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<PurchaseCreditInputs>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: { amount: '$ 10', paymentID: '' },
  });

  useEffect(() => {
    if (cards?.length) {
      setValue('paymentID', cards?.[0]?.id);
      setPaymentMethodID(cards?.[0]?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cards]);

  const changePaymentMethod = (e: RadioChangeEvent) => {
    setValue('paymentID', e.target.value);
    setPaymentMethodID(e.target.value);
  };

  const availableCreditOptions = [10, 20, 50, 100, 200, 500, 1000];

  return (
    <form
      onSubmit={event => {
        event?.stopPropagation();
        event?.preventDefault();
        return handleSubmit(formSubmit)(event);
      }}
      id='purchase-credit-form'
    >
      <SelectInput
        name='amount'
        id='amount'
        control={control}
        label={t('form.label.selectAmount', 'Select Amount')}
        errors={errors}
        size='large'
      >
        {availableCreditOptions?.map(creditValue => (
          <Select.Option key={creditValue} value={creditValue}>
            $ {creditValue}
          </Select.Option>
        ))}
      </SelectInput>
      <Radio.Group className='w-full' onChange={changePaymentMethod} value={paymentMethodID}>
        <Space className='w-full' size={14} direction='vertical'>
          {renderCards}
        </Space>
      </Radio.Group>
    </form>
  );
}
