import { useEffect } from 'react';
import { notification } from 'antd';
import { RiWifiFill, RiWifiOffFill } from 'react-icons/ri';
import { isNetworkAvailable, userOnlineStatus } from 'services/apollo/reactiveVars';

export default function withNetworkDetector(ComposedComponent: React.ComponentType) {
  const NetworkDetector = (props: any) => {
    const handleConnectionChange = async () => {
      const condition = navigator.onLine ? 'online' : 'offline';
      if (condition === 'online') {
        isNetworkAvailable(true);
        notification.open({
          message: 'Connection successfully restored.',
          icon: <RiWifiFill />,
          className: 'connection-online',
          placement: 'bottomLeft',
          closeIcon: <>no</>,
        });

        return;
      }

      isNetworkAvailable(false);
      userOnlineStatus(false);
      notification.open({
        message: 'KrispCall is trying to re-connect',
        icon: <RiWifiOffFill />,
        className: 'connection-offline',
        placement: 'bottomLeft',
        closeIcon: <></>,
      });
    };

    useEffect(() => {
      window.addEventListener('offline', handleConnectionChange);
      window.addEventListener('online', handleConnectionChange);
      return () => {
        window.removeEventListener('offline', handleConnectionChange);
        window.removeEventListener('online', handleConnectionChange);
      };
    });
    return <ComposedComponent {...props} />;
  };

  return NetworkDetector;
}
