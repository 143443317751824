import { ReactElement } from 'react';
import { Dropdown, Menu, MenuProps } from 'antd';

import { MessageDotsFill, PhoneDownFill, PhoneFill, PlusCircle } from 'components/atoms/icon';
import { parsePhoneNumber } from 'components/utils/phone-lib';

import { StyledMenu } from './Styles';
import { useDeclineWithMessage } from './useDeclineWithMessage';

import ClientInfo from '../common/client-info';
import { WidgetActionButton } from '../call-active/Styles';
import useActiveCallWidgetContext from '../hooks/useActiveCallWidgetContext';
import useCallWidgetDrawerContext from '../hooks/useCallWidgetDrawerContext';
import useCallWidgetContext from '../hooks/useCallWidgetContext';
import { BannerType } from '../types';
import { CALL_INCOMING_SCREENS } from '../constants';

const CUSTOM_QUICK_SMS = [
  'Sorry, I’m on a call right now',
  'I’ll call you right back',
  'Can’t talk. Call you in 15 minutes',
  'In a meeting, call you right back',
];

export default function IncomingCall(): ReactElement {
  const { CUSTOM_MESSAGE } = CALL_INCOMING_SCREENS;
  const { handleCallAnswer, handleCallReject, clientInfo } = useActiveCallWidgetContext();
  const { setSelectedDrawer } = useCallWidgetDrawerContext();
  const { setBanner } = useCallWidgetContext();
  const { onSelectMessageTemplate } = useDeclineWithMessage();
  const { phoneFormatIntl } = parsePhoneNumber(clientInfo?.number ?? '');

  const handleMenuClick: MenuProps['onClick'] = e => {
    if (e.key === CUSTOM_MESSAGE) {
      setSelectedDrawer(CUSTOM_MESSAGE);
      setBanner?.({
        title: `Incoming Call ${clientInfo?.name || phoneFormatIntl}`,
        closable: false,
        type: BannerType.success,
        icon: <PhoneFill className='w-4.5 h-4.5' />,
        showIcon: true,
        alignContent: 'center',
      });
      return;
    }
    onSelectMessageTemplate(e.key);
  };

  const menu = (
    <StyledMenu onClick={handleMenuClick}>
      <h3>Respond with</h3>
      {CUSTOM_QUICK_SMS?.map(sms => (
        <Menu.Item key={sms}>{sms}</Menu.Item>
      ))}
      <Menu.Item key={CUSTOM_MESSAGE}>
        <div className='flex items-center justify-between'>
          Custom Message <PlusCircle className='h-6 w-6 text-link' />
        </div>
      </Menu.Item>
    </StyledMenu>
  );

  return (
    <>
      <div className='flex items-center justify-center h-78 mt-3.5'>
        <ClientInfo variant='incoming' />
      </div>
      <div className='grid gap-2 mt-6 px-3.5'>
        <WidgetActionButton
          className='success'
          onClick={handleCallAnswer}
          icon={<PhoneFill className='w-5 h-5' />}
        >
          Answer
        </WidgetActionButton>
        <WidgetActionButton
          danger
          onClick={handleCallReject}
          icon={<PhoneDownFill className='w-5.5 h-5.5' />}
        >
          Decline
        </WidgetActionButton>
        <Dropdown
          trigger={['click']}
          overlay={menu}
          getPopupContainer={(triggerNode: HTMLElement) => triggerNode}
        >
          <WidgetActionButton className='tertiary' icon={<MessageDotsFill className='w-5 h-5' />}>
            Decline with message
          </WidgetActionButton>
        </Dropdown>
      </div>
    </>
  );
}
