import React, { useState } from 'react';
import { RiSearchLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';

import { queryParamVars, useQVars } from 'hooks/useContactVars';
import useDebounce from 'hooks/useDebounce';
import { SearchInput } from 'components/atoms';

export interface ISearchContact {
  getSearchedQuery: (val: string) => void;
}

export function Search({ getSearchedQuery }: ISearchContact) {
  const WAIT_TIME_IN_MS = 500;
  const { t } = useTranslation();
  const queryParams = useReactiveVar<any>(queryParamVars);
  const { setSearch } = useQVars(queryParamVars);
  const [searchedQuery, setSearchedQuery] = useState('');
  const debouncedQuery = useDebounce<string>(searchedQuery, WAIT_TIME_IN_MS);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setSearchedQuery(e.target.value);
    getSearchedQuery(e.target.value);
  };

  return (
    <>
      <SearchInput
        size='large'
        placeholder={t('searchContacts.placeholder', 'Search contacts')}
        prefix={<RiSearchLine />}
        onChange={handleSearch}
        value={queryParams?.search}
        className='contact-list-search'
      />
    </>
  );
}
