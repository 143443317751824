import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from 'graphql/fragments/error';
import { CAMPAIGN_RESPONSE_FIELDS } from './fragment';

export const CREATE_CAMPAIGN = gql`
  mutation createCampaigns($data: CreateCampaignsInputData!) @api(name: "salesDialer") {
    createCampaigns(data: $data) {
      status
      error {
        ...Error
      }
      data {
        ...CampaignResponseFields
      }
    }
  }
  ${CAMPAIGN_RESPONSE_FIELDS}
  ${ERROR_FRAGMENT}
`;

export const ARCHIVE_CAMPAIGN = gql`
  mutation archiveCampaign($data: ArchiveCampaignsInputData!) @api(name: "salesDialer") {
    archiveCampaign(data: $data) {
      status
      error {
        ...Error
      }
      data {
        success
      }
    }
  }

  ${ERROR_FRAGMENT}
`;

export const UPDATE_CAMPAIGN = gql`
  mutation updateCampaign($data: UpdateCampaignsInputData!) @api(name: "salesDialer") {
    updateCampaign(data: $data) {
      status
      error {
        ...Error
      }
      data {
        ...CampaignResponseFields
      }
    }
  }
  ${CAMPAIGN_RESPONSE_FIELDS}
  ${ERROR_FRAGMENT}
`;

export const CONTROL_CAMPAIGN = gql`
  mutation controlCampaign($data: ControlCampaignInput!) @api(name: "salesDialer") {
    controlCampaign(data: $data) {
      status
      error {
        ...Error
      }
      data {
        id
        status
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const SKIP_CAMPAIGN_CONVERSATION = gql`
  mutation skipCampaignConversation($data: SkipCampaignConversationInput!)
  @api(name: "salesDialer") {
    skipCampaignConversation(data: $data) {
      status
      error {
        ...Error
      }
      data {
        nextId
        contactNumber
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
