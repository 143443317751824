import {
  MY_NUMBERS,
  NUMBER_SETTINGS,
  FETCH_IVR_SETTINGS,
  FETCH_CALLERID_SETTINGS,
  FETCH_VERIFIED_CALLER_IDS,
  A2P_WORKSPACE_CAMPAIGN,
  PRORATE_NUMBER_CHARGE,
  FETCH_SIP_TRUNKING_SETTINGS,
} from './query';

import {
  SAVE_IVR_SETTINGS,
  UPDATE_CALL_STRATEGY,
  PORT_NUMBER,
  EDIT_CHANNEL_ACCESS,
  EDIT_GENERAL_SETTINGS,
  DELETE_CHANNEL,
  SETUP_CALLER_ID,
  SET_CHANNEL_CALLER_ID,
  GET_IVR_RECORDINGS,
  REMOVE_CHANNEL,
  GENERATE_SIP_SETTINGS,
} from './mutation';

export {
  MY_NUMBERS,
  NUMBER_SETTINGS,
  FETCH_IVR_SETTINGS,
  SAVE_IVR_SETTINGS,
  UPDATE_CALL_STRATEGY,
  PORT_NUMBER,
  EDIT_CHANNEL_ACCESS,
  EDIT_GENERAL_SETTINGS,
  DELETE_CHANNEL,
  SETUP_CALLER_ID,
  FETCH_CALLERID_SETTINGS,
  FETCH_VERIFIED_CALLER_IDS,
  SET_CHANNEL_CALLER_ID,
  A2P_WORKSPACE_CAMPAIGN,
  GET_IVR_RECORDINGS,
  PRORATE_NUMBER_CHARGE,
  REMOVE_CHANNEL,
  FETCH_SIP_TRUNKING_SETTINGS,
  GENERATE_SIP_SETTINGS,
};
