import { TWILIO_DEBUG } from 'constants/commons';

// interface Config {
//   allowIncomingWhileBusy?: boolean;
//   appName?: string;
//   appVersion?: string;
//   audioConstraints?: MediaTrackConstraints | boolean;
//   closeProtection?: boolean | string;
//   codecPreferences?: Connection.Codec[];
//   disableAudioContextSounds?: boolean;
//   dscp?: boolean;
//   edge?: string[] | string;
//   forceAggressiveIceNomination?: boolean;
//   maxAverageBitrate?: number;
//   rtcConfiguration?: RTCConfiguration;
//   sounds?: Partial<Record<Device.SoundName, string>>;
// }

export const deviceConfig = {
  // When set to true, Device's default behavior of silently ignoring the incoming call is removed, and the incoming call will instead cause Device to emit an "incoming" event. If accepted, the prior active call will be immediately disconnected, and the incoming call will be accepted, replacing the prior active call.
  allowIncomingWhileBusy: true,
  // A name for the application that is instantiating the Device
  appName: 'krispcall',
  // A version for the application that is instantiating the Device. This is used to improve logging in Insights by associating Insights data with a specific version of the given application. This can help track down when application-level bugs were introduced.
  appVersion: '0.1.1',
  // // Can be true or MediaTrackConstraints. Set this property to select a specific microphone, or turn off features like auto-gain control, for the entire Twilio.Device. Each web browser implements a different set of MediaTrackConstraints, so consult your browser's implementation of getUserMedia for further details. This property can also be overridden for each Twilio.Connection. See also our knowledge base article on audioConstraints. Note: If an input device is selected using Device.audio.setInputDevice, this parameter will be ignored and the set input device will be used instead.
  // audioConstraints: true,
  // // The maximum amount of time, in milliseconds, to wait between websocket reconnects. The SDK uses an exponential backoff, so initially reconnection will be attempted much faster (100ms). The minimum acceptable value here is 3000.
  // backoffMaxMs: 20000,
  // // An ordered list of preferred codecs. Currently, 'pcmu' and 'opus' are supported. PCMU will remain default until the next breaking release, however we recommend testing and using Opus as it can provide better quality for lower bandwidth, particularly noticeable in poor network conditions.
  // codecPreferences: ['pcmu', 'opus'],
  // // Can be boolean or string. Setting this property to true will enable a dialog prompt with the text "A call is currently in progress. Leaving or reloading this page will end the call." when closing a page which has an active connection. Setting the property to a string will change the text of the message prompt. NOTE: Where custom text is not supported on the browser, Twilio will display the browser's default dialog.
  closeProtection: true,
  // // Can be true or false. Set this property to true to enable debug logging in your browser console. This can be overridden using loglevel APIs. Please see SDK Logger section for details
  // debug: TWILIO_DEBUG,
  // // Specifies whether Twilio Client will ask WebRTC to set the Differentiated Services field in the packet headers for all WebRTC traffic. Note: At this time, DSCP is only supported in Google Chrome, and does not work on Windows.
  // dscp: true,
  // // The Edge location to connect to. In case edge is of type array, it should contain the list of edge strings sorted by priority order where the first element has the highest priority and the last element has the lowest priority.
  // edge: 'roaming',
  // // When set to true, allows for detecting when media connection fails which will trigger automatic media reconnection, and for detecting when media connection is restored, as well as the Connection#reconnecting and Connection#reconnected` events.
  enableIceRestart: true,
  // When set to true, the new ringing state and Connection#ringing event will be enabled for calls this Device makes. These features are intended to be used alongside the answerOnBridge Dial property (eg: <Dial answerOnBridge=true></Dial>) to provide better SDK feedback on the call status.
  enableRingingState: true,
  // // If true, replaces default DTMF tones with mock DTMF tones. This prevents double-tones in some cases. This will become default in the next breaking release.
  // fakeLocalDTMF: false,
  // // An array of custom ICE servers to use to connect media. If you have custom Twilio TURN servers from Twilio NTS, you can specify them here.
  // iceServers: null,
  // // Max average bitrate to better control how much bandwidth your VoIP application should use. Only applicable when using Opus codec.
  // maxAverageBitrate: null,
  // // Pass a custom RTCConfiguration object to override what Twilio uses to create RTCPeerConnections.
  // rtcConfiguration: null,
  // // An object mapping sound names (property) to custom URLs (string value). Note that incoming ringtone will loop for at least 2 seconds and as long as the incoming call is pending. All other sounds will play once;
  // sounds: null,
  // Can be true or false. Set this property to false to disable logging warnings to your browser console. This can be overridden using loglevel APIs.
  warnings: true,
};
