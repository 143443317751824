import { Select } from 'antd';
import { Trans } from 'react-i18next';

import { useTwilioContext } from 'lib/twilio';
import { SelectInput } from 'components/molecules/fields';
import useMediaDevices from 'lib/twilio/hooks/useMediaDevices';
import { useAudioDeviceHandlers } from 'hooks/useAudioDeviceHandlers';

import * as S from './Styles';
import BackButtonHeader from '../../call-active/back-button-header';

const { Option } = Select;

export default function AudioSettings() {
  const { audioInputDevices, audioOutputDevices } = useMediaDevices();
  const { deviceInstance } = useTwilioContext();

  const {
    activeMic,
    activeSpeaker,
    handleAudioInputChange,
    handleAudioOutputChange,
  } = useAudioDeviceHandlers();

  return (
    <S.FieldWrapper className='pt-12 px-3.5'>
      <BackButtonHeader className='mb-4' title='Audio Settings' showArrowIcon={false} />
      <div data-cy='input-device-select' className='mb-4.5'>
        <SelectInput
          name='audio-input'
          label={
            <span className='text-gray-700 text-sm font-medium leading-none'>
              <Trans i18nKey='form.label.inputDevices'>Input</Trans>
            </span>
          }
          defaultValue={activeMic}
          onChange={handleAudioInputChange as any}
          disabled={!deviceInstance?.audio?.availableInputDevices} // setInputDevice is not supported in firefox
        >
          {audioInputDevices?.map((device: any) => {
            return (
              <Option value={device.deviceId} key={device.deviceId}>
                {device.label}
              </Option>
            );
          })}
        </SelectInput>
      </div>
      <div data-cy='output-device-select'>
        <SelectInput
          name='audio-output'
          label={
            <span className='text-gray-700 text-sm font-medium leading-none'>
              <Trans i18nKey='form.label.outputDevices'>Output</Trans>
            </span>
          }
          defaultValue={activeSpeaker}
          onChange={handleAudioOutputChange as any}
          disabled={!deviceInstance?.audio?.isOutputSelectionSupported} // not supported in firefox
        >
          {audioOutputDevices?.map((device: any) => {
            return (
              <Option value={device.deviceId} key={device.deviceId}>
                {device.label}
              </Option>
            );
          })}
        </SelectInput>
      </div>
    </S.FieldWrapper>
  );
}
