import { memo, useState } from 'react';

import WidgetSidebar from './widget-sidebar';
import AudioSettings from './nav-screens/audio-settings';
import { WIDGET_NAV_SCREENS } from './constants';
import CallWidget from './nav-screens/call-widget';

function WidgetBody() {
  const { CALL_WIDGET, AUDIO_SETTINGS } = WIDGET_NAV_SCREENS;
  const [selectedNavScreen, setSelectedNavScreen] = useState(CALL_WIDGET);

  const mapComponent = {
    [CALL_WIDGET]: <CallWidget />,
    [AUDIO_SETTINGS]: <AudioSettings />,
  };

  return (
    <section>
      <WidgetSidebar
        selectedNavScreen={selectedNavScreen}
        setSelectedNavScreen={setSelectedNavScreen}
      />
      <main className='border-l relative border-extra-widgetBorder w-full h-full '>
        {mapComponent[selectedNavScreen]}
      </main>
    </section>
  );
}

export default memo(WidgetBody);
