import { useContext } from 'react';
import { Channel, Client } from 'generated/graphql';

import { ActiveCallWidgetContext } from '../context/ActiveCallWidgetContext';

interface IActiveCallWidgetContext {
  callDurationTime?: string;
  mute?: boolean;
  isSpeakerMute?: boolean;
  recording?: boolean;
  recordingTime?: string;
  loadingCallRecord?: boolean;
  autoRecordingEnabled?: boolean;
  hold?: string;
  loadingCallHold?: boolean;
  clientInfo?: Client;
  channelInfo?: Channel;
  keypadNumberInput?: string;
  note?: Record<string, any>;
  loadingSaveNote?: boolean;
  loadingCallTransfer?: boolean;
  selectedMoreOption?: string;
  loadingCampaignSaveNote?: boolean;
  campaignCallNote?: Record<string, any>;
  handleCallAnswer?: (args?: any) => void;
  handleCallReject?: (args?: any) => void;
  handleToggleRecording?: (args?: any) => void;
  handleToggleHold?: (args?: any) => void;
  handleToggleMute?: (args?: any) => void;
  handleToggleMuteSpeaker?: (args?: any) => void;
  handleDialKeypad?: (key: string, tone?: number[]) => void;
  handleNoteSave?: (args?: string) => void;
  handleCallTransfer?: (args?: any, data?: string) => void;
  handleSelectMoreAction?: (args?: string) => void;
  handleExecuteCampaignAction?: (id?: string, action?: string) => void;
  handleCampaignNoteSave?: (args?: any, action?: 'create' | 'edit') => void;
  handleSkipCampaignCallQueue?: (conversationId: string, campaignId: string) => void;
  handleResetCallWidget?: () => void;
  handleDropVoicemail?: (conversationId: string, campaignId: string) => void;
  removeTrailingKeypadNumber?: () => void;
}

export default function useActiveCallWidgetContext() {
  const widgetState = useContext<IActiveCallWidgetContext>(ActiveCallWidgetContext);
  if (!widgetState) {
    throw new Error('useActiveCallWidgetContext must be used within a CallWidgetProvider');
  }
  return widgetState;
}
