import { Alert } from 'antd';
import styled from 'styled-components';

export const AlertWrapper = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
`;

export const CustomAlert = styled(Alert)`
  &.ant-alert {
    height: 36px;
    padding: 6px 32px;
    line-height: 14px;
    border-radius: 0px;
    pointer-events: all;
    border: none;
    z-index: 9;
    &.has-bordered-btn {
      padding: 7px 32px;
    }
    &.alert-sm {
      padding: 8px;
      &.has-bordered-btn {
        padding: 7px;
      }
    }
    .ant-alert-content {
      display: flex;
      justify-content: center;
    }
    .ant-alert-icon {
      display: none;
    }
    .ant-alert-message {
      width: 100%;
    }
    p {
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      color: #ffffff;
    }
    .ant-alert-message {
      width: fit-content;
    }
    &-success {
      background: #4c9610;
      box-shadow: none;
      .ant-alert-message {
        color: #ffffff;
      }
      button {
        font-family: 'Heebo', sans-serif;
        background: none;
        color: #ffffff;
        border: none;
        outline: none;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        &:disabled {
          color: #b7d59f;
        }
      }
    }
    &-error {
      background: #db312b;
      box-shadow: none;
      button {
        box-sizing: border-box;
        border: 0.5px solid #ffffff;
        border-radius: 4px;
        padding: 0px 8px;
        font-family: 'Heebo', sans-serif;
        font-weight: 600;
        font-size: 13px;
        color: #ffffff;
        margin-left: 8px;
      }
    }
    &-warning {
      background: #e07408;
      box-shadow: none;
      button {
        box-sizing: border-box;
        border-radius: 4px;
        padding: 0px 8px;
        font-family: 'Heebo', sans-serif;
        font-weight: 600;
        font-size: 13px;
        color: #ffffff;
        margin-left: 8px;
      }
    }
    &-info {
      background: #1a63f4;
      box-shadow: none;
      button {
        box-sizing: border-box;
        border-radius: 4px;
        padding: 0px 8px;
        font-family: 'Heebo', sans-serif;
        font-weight: 600;
        font-size: 13px;
        color: #ffffff;
        margin-left: 8px;
      }
    }
  }
  .btn-outline {
    height: 24px;
    min-width: 6.0625rem;
    padding: 1px 8px !important;
    border: 1px solid #ffffff;
    display: inline-block;
    &:hover {
      border-color: white;
    }
  }
`;
