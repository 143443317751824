import Widget from './Widget';
import { CallWidgetProvider } from './context/CallWidgetContext';
import { CallWidgetDrawerProvider } from './context/CallWidgetDrawerContext';
import { PhoneInputProvider } from './context/PhoneInputContext';
import { TouchToneProvider } from './touch-tone/TouchToneProvider';

export default function DialerWidgetV3() {
  return (
    <CallWidgetProvider>
      <CallWidgetDrawerProvider>
        <TouchToneProvider>
          <PhoneInputProvider>
            <Widget />
          </PhoneInputProvider>
        </TouchToneProvider>
      </CallWidgetDrawerProvider>
    </CallWidgetProvider>
  );
}
