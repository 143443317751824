const SignalIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M7.58485 14.6943V20.2421C7.58485 20.791 7.1358 21.24 6.58697 21.24H4.30123C3.75012 21.24 3.30334 20.7933 3.30334 20.2421V14.6943C3.30334 14.1432 3.75012 13.6964 4.30123 13.6964H6.58697C7.13808 13.6964 7.58485 14.1432 7.58485 14.6943Z'
        fill='#10BC3B'
      />
      <path
        d='M14.1403 9.22582V20.2422C14.1403 20.791 13.6912 21.24 13.1424 21.24H10.8566C10.3055 21.24 9.85876 20.7933 9.85876 20.2422V9.22582C9.85876 8.67698 10.3078 8.22794 10.8566 8.22794H13.1424C13.6935 8.22794 14.1403 8.6747 14.1403 9.22582Z'
        fill='#10BC3B'
      />
      <path
        d='M20.6968 3.75728V20.2421C20.6968 20.791 20.2477 21.24 19.6989 21.24H17.4132C16.8621 21.24 16.4153 20.7932 16.4153 20.2421V3.75728C16.4153 3.20617 16.8621 2.7594 17.4132 2.7594H19.6989C20.25 2.7594 20.6968 3.20616 20.6968 3.75728Z'
        fill='#10BC3B'
      />
    </svg>
  );
};
export default SignalIcon;
