import { useEffect, useRef } from 'react';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import jwtDecode from 'jwt-decode';

import { webRtcPeerConnectionEnabled } from 'services/apollo/reactiveVars';
import useLocalStorage from 'hooks/useLocalStorage';
import { WORKSPACES } from 'graphql/foundation';
import { useDefaultCardQuery } from 'lib/chargebee/hooks/useDefaultCardQuery';

export const useLoggedInMember = () => {
  const [user] = useLocalStorage('user', '');
  const [tokens] = useLocalStorage('_tokens', '');

  const accessToken = tokens?.accessToken;

  const [loadWorkspaces, { data: workspaceData }] = useLazyQuery(WORKSPACES);

  const { defaultPaymentCard } = useDefaultCardQuery();
  const { brand, email } = defaultPaymentCard || {};
  const isPaypalDefaultCard = !brand && !!email;

  const webRtcPeerConnection = useReactiveVar<any>(webRtcPeerConnectionEnabled);

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  useEffect(() => {
    if (mounted.current) {
      loadWorkspaces();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [activeWorkspaceId] = useLocalStorage('activeWorkspaceId', null);

  const workspaces = workspaceData?.workspaces?.data ?? user?.details?.workspaces;
  const loggedInMemberInfo = workspaces?.find((item: any) => item.id === activeWorkspaceId) ?? {};
  const {
    memberId,
    plan,
    twilioStatus,
    reviewStatus,
    numberSubscription: numSubcription,
    kycVerified,
  } = loggedInMemberInfo || {};
  const { remainingDays, subscriptionActive, remainingGracePeriod } = plan || {};
  const loggedInMemberId = memberId;
  const planRemainingDays = remainingDays;
  const isEmailVerifiedUser = user?.details?.userProfile?.status === 'Verified';
  const userEmail = user?.details?.userProfile?.email;
  const username = `${user?.details?.userProfile?.firstname} ${user?.details?.userProfile?.lastname}`;
  const isUserSuspended = twilioStatus === 'Suspended';

  const isSubscriptionCancelled = subscriptionActive === 'Inactive';
  const userAuthId = user?.details?.id;
  const isExpired = subscriptionActive === 'Expired' ?? false;

  const isDeletedWorkspace =
    workspaces?.find((workspace: any) => workspace.id === activeWorkspaceId)?.status === 'Inactive';
  const isUnderReview = reviewStatus === 'Under Review';
  const isSubscriptionExpired = isExpired || isUnderReview;
  const isOnGracePeriod = !isSubscriptionExpired && remainingDays <= 0;

  const numberSubscription = numSubcription;
  const isNumberSubscriptionExpired = numberSubscription?.subscriptionStatus === 'Expired';
  const isAutoRenewFailed = numberSubscription?.autoRenew === 'Failed';
  const isKYCVerified = kycVerified ?? false;

  const isAlertBarVisible =
    !isKYCVerified ||
    isUserSuspended ||
    !webRtcPeerConnection ||
    isNumberSubscriptionExpired ||
    isAutoRenewFailed ||
    isSubscriptionExpired ||
    isOnGracePeriod ||
    isPaypalDefaultCard;

  const accessTokenDecoded = accessToken && jwtDecode<any>(accessToken);
  const userRole: string = accessTokenDecoded?.user_claims?.role;

  return {
    accessToken,
    activeWorkspaceId,
    loggedInMemberInfo,
    loggedInMemberId,
    planRemainingDays,
    isAlertBarVisible,
    isSubscriptionCancelled,
    userAuthId,
    isSubscriptionExpired,
    isDeletedWorkspace,
    isUserSuspended,
    isEmailVerifiedUser,
    isUnderReview,
    userEmail,
    numberSubscription,
    isAutoRenewFailed,
    isNumberSubscriptionExpired,
    isKYCVerified,
    username,
    userRole,
    isOnGracePeriod,
    remainingGracePeriod,
    isPaypalDefaultCard,
  };
};
