import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from 'graphql/fragments/error';
import { PAGE_INFO } from 'graphql/fragments/pagination';

export const GET_CAMPAIGNS_LIST = gql`
  query getCampaignsList($fetchArchived: Boolean!, $params: CampaignListCursor)
  @api(name: "salesDialer") {
    getCampaignsList(fetchArchived: $fetchArchived, params: $params) {
      data {
        pageInfo {
          ...PageInfo
        }
        edges {
          cursor
          node {
            id
            workspaceId
            campaignName
            assigneName
            assigneId
            dialingNumber
            dialingNumberId
            callingDatacenter
            callRecordingEnabled
            voicemailEnabled
            voicemailId
            cooloffPeriodEnabled
            coolOffPeriod
            callAttemptsEnabled
            callAttemptsCount
            callAttemptsGap
            callScriptEnabled
            isArchived
            isContactListHidden
            callScriptId
            contactListId
            nextNumberToDial
            campaignStatus
            contactCount
            createdOn
            createdBy
            contactListName
          }
        }
      }
      error {
        ...Error
      }
      status
    }
  }
  ${PAGE_INFO}
  ${ERROR_FRAGMENT}
`;

export const CAMPAIGN_CALL_ANALYTICS = gql`
  query campaignAnalytics($input: CampaignAnalyticsInput!, $params: CampaignAnalyticsCursor)
  @api(name: "salesDialer") {
    campaignAnalytics(input: $input, params: $params) {
      data {
        edges {
          cursor
          node {
            id
            status
            contactName
            contactNumber
            callDuration
            recordingUrl
            recordingDuration
            callStartTime
            conversationNote
            reasonMessage
          }
        }
        pageInfo {
          ...PageInfo
        }
      }
      error {
        ...Error
      }
    }
  }
  ${PAGE_INFO}
  ${ERROR_FRAGMENT}
`;

export const GET_CAMPAIGN_DETAIL = gql`
  query campaignDetails($input: CampaignDetailsInput!) @api(name: "salesDialer") {
    campaignDetails(input: $input) {
      data {
        id
        workspaceId
        campaignName
        assigneeName
        dialingNumber
        campaignStatus
        callRecordingEnabled
        voicemailEnabled
        callScriptEnabled
        voicemailId
        callScriptId
        coolOffPeriodEnabled
        callingDatacenter
        isArchived
      }
      error {
        ...Error
      }
      status
    }
  }
  ${ERROR_FRAGMENT}
`;

export const CAMPAIGNS_COUNT = gql`
  query campaignsCount @api(name: "salesDialer") {
    campaignsCount {
      data {
        archived
        active
      }
      error {
        ...Error
      }
      status
    }
  }
  ${ERROR_FRAGMENT}
`;
