import { useTranslation } from 'react-i18next';
import cache from 'services/apollo/cache';

import { useQuery } from '@apollo/client';
import { ToastMessage } from 'components/atoms';
import { GET_WORKSPACE_CREDIT } from 'graphql/foundation';

export function useWorkspaceCredit() {
  const { t } = useTranslation();
  const { refetch } = useQuery(GET_WORKSPACE_CREDIT);

  const getWorkspaceCredit = async () => {
    try {
      const response = await refetch();
      const credit = response?.data?.getWorkspaceCredit?.data?.currentCredit || 0;
      cache.modify({
        fields: {
          planOverview: () => {},
          workspaces: () => {},
        },
      });
      return credit;
    } catch (error) {
      console.error(error);
      return 0;
    }
  };

  const sufficientCredit = async () => {
    const credit = await getWorkspaceCredit();
    const creditIsSufficient = !(credit <= 0.5);
    if (!creditIsSufficient) {
      ToastMessage({
        content: t('error.inSufficientCredit', 'Insufficient Credit.'),
        type: 'danger',
      });
    }
    return creditIsSufficient;
  };

  return { sufficientCredit };
}
