import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextAreaInput } from 'components/molecules/fields';

import { Note, Maybe } from 'generated/graphql';
import { ActionsModal } from 'components/organisms';

import NoteDisplayCard from './NoteDisplayCard';
import useClientNote from './hooks/useClientNote';
import useNoteModal from './hooks/useNoteModal';
import { ContactDetailContext } from './ContactDetailContext';
import * as S from './Styles';
import { useClientNoteForm } from './hooks/useClientNoteForm';

interface Props {
  contactNumber?: Maybe<string>;
  channelId?: string;
  notes?: Note[];
}

const ClientNotes: React.FunctionComponent<Props> = props => {
  const { notesFieldRef } = useContext(ContactDetailContext);
  const { t } = useTranslation();
  // eslint-disable-next-line prettier/prettier
  const {
    selectedNoteId,
    visibleNoteDeleteModal,
    closeNoteDeleteModal,
    openNoteDeleteModal,
  } = useNoteModal();
  const { addContactNote, removeContactNote, removingNotes } = useClientNote({
    closeNoteDeleteModal,
  });
  const { contactNumber, channelId, notes: responseNotes } = props;
  const [notes, setNotes] = useState<Note[]>([]);

  useEffect(() => {
    if (responseNotes) setNotes([...responseNotes].reverse());
  }, [responseNotes]);

  const { methods, handleNotesChanges, handleNoteDelete } = useClientNoteForm({
    type: 'add',
    addContactNote,
    removeContactNote,
    contactNumber,
    channelId,
    selectedNoteId,
  });
  const {
    control,
    formState: { errors },
  } = methods;

  return (
    <>
      <ActionsModal
        isModalOpen={visibleNoteDeleteModal}
        onOk={handleNoteDelete}
        loading={removingNotes}
        onCancel={closeNoteDeleteModal}
        onModalCancel={closeNoteDeleteModal}
        title={t('deleteNote', 'Delete Note')}
        info={t(
          'deleteNoteWarning',
          'Are you sure you want to delete this note. This action cannot be undone.',
        )}
        action='delete'
        btnText='Delete'
      />
      <S.ClientNotesContainer>
        <form className='mb-0.5'>
          <TextAreaInput
            autoSize={{ minRows: 3, maxRows: 3 }}
            ref={notesFieldRef}
            name='note'
            id='noteBox'
            control={control}
            onPressEnter={handleNotesChanges}
            placeholder={t('addNote.placeholder', 'Write a note...')}
            errors={errors}
          />
        </form>
        {(notes || []).map((note: Note) => (
          <NoteDisplayCard key={note.id} note={note} openDeleteModal={openNoteDeleteModal} />
        ))}
      </S.ClientNotesContainer>
    </>
  );
};

export default ClientNotes;
