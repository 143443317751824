import AutoCompleteField from 'components/molecules/fields/AutoCompleteField';

import { AutoCompleteFieldWrapper, Wrapper } from './Styles';
import { ContactAutoCompleteOption } from './ContactAutoCompleteOption';
import CountrySelect from '../country-select';
import usePhoneInputContext from '../../hooks/usePhoneInputContext';

const PhoneAutoCompleteInput = () => {
  const {
    searchedText,
    control,
    handleSearch,
    handleSelect,
    autoCompleteContacts,
    selectedContact,
  } = usePhoneInputContext();

  const options = autoCompleteContacts?.map((data: any) => {
    const { number, profilePicture, name } = data?.node || {};
    return {
      value: number,
      label: (
        <ContactAutoCompleteOption
          number={number}
          searchedText={searchedText}
          name={name}
          profilePicture={profilePicture}
        />
      ),
      data: { name, number },
    };
  });

  return (
    <Wrapper>
      <CountrySelect />
      <AutoCompleteFieldWrapper>
        <AutoCompleteField
          placeholder='Enter phone number or name'
          control={control}
          name='number'
          options={searchedText ? options : []}
          onSelect={handleSelect}
          onSearch={handleSearch}
          dropdownMatchSelectWidth={false}
          dropdownStyle={{ minWidth: 293 }}
          listHeight={300}
        />
      </AutoCompleteFieldWrapper>
      {selectedContact?.name && <div className='client-name'>{selectedContact?.name}</div>}
    </Wrapper>
  );
};
export default PhoneAutoCompleteInput;
