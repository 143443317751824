import { gql } from '@apollo/client';
import { CHANNEL_NODE } from 'graphql/channel/fragments';

export const WORKSPACE_NODE = gql`
  fragment WorkspaceFields on Workspace {
    id
    title
    photo
    kycVerified
    channels {
      ...ChannelFields
    }
    reviewStatus
    twilioStatus
    plan {
      remainingDays
      subscriptionActive
      currentCredit
      planDetail {
        title
      }
    }
    notification
    status
    features {
      id
      title
      status
    }
  }
  ${CHANNEL_NODE}
`;

export const MEMBER_NODE = gql`
  fragment MemberNodeFields on MemberNode {
    id
    firstname
    lastname
    role
    gender
    email
    createdOn
    profilePicture
    memberRate {
      rate
    }
    numbers {
      name
      number
      country
      countryLogo
      countryCode
      settings {
        shared
      }
    }
    planRate {
      planRate
    }
    unSeenMsgCount
    online
    onCall
    last_message {
      createdOn
      message
    }
  }
`;
export const INVITE_NODE = gql`
  fragment InviteNodeFields on InviteNode {
    id
    role
    code
    email
    createdOn
    type
    status
  }
`;
