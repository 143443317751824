import { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { produce } from 'immer';

import * as path from 'constants/routes';
import apolloCache from 'services/apollo/cache';
import AddActionButton from 'components/molecules/button/AddActionButton';
import { initialWorkspaceMemberParams } from 'components/pages/settings/members/hooks/useMembersQVars';
import { WORKSPACE_MEMBERS } from 'graphql/foundation';
import { Query } from 'generated/graphql';
import { AuthContext } from 'contexts/auth/AuthContext';
import { Tooltip } from 'components/atoms';
import { NavHelperContext } from '../NavHelperContext';

export default function useWorkspaceBar() {
  const history = useHistory();
  const { teams, chatSubscriptionData } = useContext<any>(NavHelperContext);

  const { loggedInMemberId, isSubscriptionExpired } = useContext(AuthContext);
  const [openTeam, setOpenTeam] = useState<boolean>(false);
  const toggleTeamModal = () => setOpenTeam(prevState => !prevState);

  const [openMember, setOpenMember] = useState<boolean>(false);
  const toggleMemberModal = () => setOpenMember(prevState => !prevState);

  const [invitedMemberEmail, setInvitedMemberEmail] = useState('');
  const [visibleInvitationSentModal, setVisibleInvitationSentModal] = useState(false);
  const toggleInvitationSentModal = () => setVisibleInvitationSentModal(prevState => !prevState);
  const openInvitationSentModal = (email: string) => {
    toggleInvitationSentModal();
    setInvitedMemberEmail(email);
  };

  function handlePannelAction(pannel: string) {
    const pannels: any = {
      team: () => toggleTeamModal(),
      members: () => toggleMemberModal(),
      numbers: () => history.push(`${path.SETTINGS}${path.NUMBERS}${path.BUY}`),
    };
    return pannels[pannel]();
  }
  const addAction = (toolTipTitle: string, pannel: string, hasAccess: boolean) =>
    hasAccess && !isSubscriptionExpired ? (
      <Tooltip title={toolTipTitle} placement='right'>
        <div>
          <AddActionButton
            onClick={event => {
              event.stopPropagation();
              if (isSubscriptionExpired) {
                history.push(`${path.SETTINGS}${path.PLAN_SUBS}`);
                return;
              }
              handlePannelAction(pannel);
            }}
          />
        </div>
      </Tooltip>
    ) : (
      <></>
    );

  useEffect(() => {
    if (chatSubscriptionData) {
      const { message } = chatSubscriptionData?.workspaceChat || {};
      const cachedData: Pick<Query, 'allWorkspaceMembers'> | null = apolloCache.readQuery({
        query: WORKSPACE_MEMBERS,
        variables: {
          pageParams: initialWorkspaceMemberParams,
        },
      });
      const newData = produce(
        cachedData,
        (draft: Pick<Query, 'allWorkspaceMembers'> | null | any) => {
          if (draft?.allWorkspaceMembers?.data?.edges) {
            const index = draft.allWorkspaceMembers.data.edges.findIndex((dt: any) => {
              return dt?.node?.id === message?.sender?.id && dt?.node?.id !== loggedInMemberId;
            });
            if (index !== -1) {
              const memberEdge = draft.allWorkspaceMembers.data.edges[index];
              const unSeenMsgCount = memberEdge?.node?.unSeenMsgCount
                ? memberEdge?.node?.unSeenMsgCount + 1
                : 1;
              draft.allWorkspaceMembers.data.edges[index] = {
                ...memberEdge,
                cursor: memberEdge?.cursor,
                node: { ...memberEdge?.node, unSeenMsgCount },
              };
            }
          }
        },
      );
      apolloCache.writeQuery({
        query: WORKSPACE_MEMBERS,
        variables: {
          pageParams: {
            pageParams: initialWorkspaceMemberParams,
          },
        },
        data: newData,
      });
    }
  }, [chatSubscriptionData, loggedInMemberId]);

  return {
    addAction,
    teams,
    openTeam,
    openMember,
    toggleMemberModal,
    toggleTeamModal,
    visibleInvitationSentModal,
    toggleInvitationSentModal,
    invitedMemberEmail,
    openInvitationSentModal,
  };
}
