import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import iEmpty from 'lodash.isempty';

import { contactInputs } from 'schema-validation';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

interface IUseClientNotForm {
  type: 'add' | 'edit';
  addContactNote?: any;
  editContactNote?: any;
  removeContactNote?: any;
  contactNumber?: any;
  channelId?: any;
  selectedNoteId?: any;
}

export const useClientNoteForm = ({
  type = 'add',
  addContactNote,
  editContactNote,
  removeContactNote,
  contactNumber,
  channelId,
  selectedNoteId,
}: IUseClientNotForm) => {
  const { contactId } = useParams<Record<string, string | undefined>>();
  const isNoteEditingForm = type === 'edit';

  const schema = yup.object().shape({
    note: contactInputs.note,
  });

  const methods = useForm<{ note: string }>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const {
    setValue,
    reset,
    formState: { errors },
  } = methods;

  useEffect(() => {
    reset();
  }, [reset, contactId]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleNotesChanges = async (e: any) => {
    if (e.shiftKey) {
      return;
    }
    e.preventDefault();
    const title = e.target.value;
    if (!iEmpty(errors)) return;
    const payload = isNoteEditingForm
      ? {
          id: selectedNoteId,
          data: {
            title,
          },
        }
      : {
          contact: contactNumber,
          data: {
            title,
            channelId,
          },
        };
    if (isNoteEditingForm) {
      editContactNote(payload);
      setValue('note', title);
    } else {
      addContactNote(payload);
      setValue('note', '');
    }
  };

  const handleNoteDelete = () => {
    const payload = {
      id: selectedNoteId,
    };
    removeContactNote(payload);
  };

  return { methods, handleNotesChanges, handleNoteDelete };
};
