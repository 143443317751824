import { Avatar } from 'antd';

import { ManIcon } from 'components/atoms/icon';
import { CustomHighlighter } from 'components/molecules/highlighter';

import { NumberAutoCompleteOption } from './Styles';

interface ContactAutoComplete {
  number: string;
  searchedText: string;
  name: string;
  profilePicture: string;
}

export const ContactAutoCompleteOption = ({
  number,
  searchedText,
  name,
  profilePicture,
}: ContactAutoComplete) => {
  return (
    <NumberAutoCompleteOption data-cy='contact-auto-complete'>
      <div className='flex items-center gap-1.5'>
        <Avatar size={24} className='avatar' src={profilePicture} icon={<ManIcon />} />
        <CustomHighlighter textToHighlight={name} searchWords={[searchedText]} />
      </div>
      <CustomHighlighter className='number' textToHighlight={number} searchWords={[searchedText]} />
    </NumberAutoCompleteOption>
  );
};
