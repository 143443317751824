import { useTranslation } from 'react-i18next';
import { useState, useEffect, useContext } from 'react';

import { isValidPhoneNumber, parsePhoneNumber } from 'components/utils/phone-lib';
import { useTwilioContext } from 'lib/twilio';
import { ToastMessage } from 'components/atoms';
import { AuthContext } from 'contexts/auth/AuthContext';
import { isAssignedNumber } from 'components/organisms/common/utils';
import { useSettingQuery } from 'components/pages/settings/my-number/hooks/useSettingQuery';
import { useChannelsQuery } from 'hooks/useChannelsQuery';

export function useMakeCall() {
  const { t } = useTranslation();
  const { activeWorkspaceId, loggedInMemberId, isNumberSubscriptionExpired } = useContext(
    AuthContext,
  );
  const {
    handleDeviceOutgoing,
    state: { showPhoneWidget },
  } = useTwilioContext();
  const { numbers: channels, loading: loadingChannels } = useChannelsQuery();
  const [selectedChannel, setSelectedChannel] = useState<any>(channels[0]);
  const isPaid = selectedChannel?.numberCheckoutPrice?.basePrice > 0;
  const { configs } = useSettingQuery({ channelId: selectedChannel?.id });
  const { abilities } = configs || {};

  useEffect(() => {
    if (channels.length) {
      setSelectedChannel(channels[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channels]);

  const handleChannelSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedChannel(channels?.find(channel => channel?.id === e));
  };

  const runBasicChecksBeforeCall = (dialedNumber: string): boolean => {
    // if (showPhoneWidget) return false;
    if (!isValidPhoneNumber(dialedNumber)) {
      ToastMessage({
        content: `${t('toast.invalidPhoneNumber', 'Phone number is invalid')}`,
        type: 'danger',
      });
      return false;
    }

    const sourceNumberDetail = parsePhoneNumber(selectedChannel?.number ?? '');
    const targetNumberDetail = parsePhoneNumber(dialedNumber.trim() ?? '');

    if (
      !configs?.internationalCallAndMessages &&
      sourceNumberDetail.country !== targetNumberDetail.country
    ) {
      ToastMessage({
        content: `${t(
          'toast.internationalCallsDisabled',
          'International Calls and Messages Disabled. Contact your Administrator',
        )}`,
        type: 'danger',
      });
      return false;
    }
    if (isAssignedNumber(channels, dialedNumber)) {
      ToastMessage({
        content: `${t(
          'toast.dialAssignedNumberError',
          'Number is assigned to one of your channel',
        )}`,
        type: 'danger',
      });
      return false;
    }
    if (!abilities?.call) {
      ToastMessage({
        content: `${t('toast.callNotSupported', 'Call not supported')}`,
        type: 'danger',
      });
      return false;
    }
    return true;
  };

  const generateParams = (dialedNumber: string) => {
    return {
      To: dialedNumber.trim(),
      From: selectedChannel?.number,
      workspace_sid: activeWorkspaceId,
      channel_sid: selectedChannel?.id,
      agent_id: loggedInMemberId,
    };
  };

  const makeCall = async (formData: any) => {
    if (isNumberSubscriptionExpired && isPaid) {
      ToastMessage({
        content: 'Number subscription has been expired. Please try with active number',
        type: 'danger',
      });
      return;
    }
    const { number } = formData;
    const { number: dialedNumber, country } =
      parsePhoneNumber(`+${number.replace(/\+/g, '')} `) || {};
    if (!dialedNumber) {
      ToastMessage({
        content: `${t('toast.invalidPhoneNumber', 'Phone number is invalid')}`,
        type: 'danger',
      });
      return;
    }

    const isOk = runBasicChecksBeforeCall(dialedNumber as string);
    if (isOk) {
      /** Store recently dialed number's country code in localstorage */
      localStorage.setItem('_dialer-default-country', country);
      handleDeviceOutgoing(generateParams(dialedNumber as string));
    }
  };

  return {
    channels,
    loadingChannels,
    selectedChannel,
    makeCall,
    handleChannelSelect,
    showPhoneWidget,
  };
}
