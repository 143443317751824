export default function NoMatchFound() {
  return (
    <svg
      width='200'
      height='200'
      viewBox='0 0 200 200'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='100' cy='100' r='100' fill='#E9F0FE' />
      <path
        d='M101.719 140C122.861 140 140 122.861 140 101.719C140 80.5766 122.861 63.4375 101.719 63.4375C80.5766 63.4375 63.4375 80.5766 63.4375 101.719C63.4375 122.861 80.5766 140 101.719 140Z'
        fill='#F24976'
      />
      <path
        d='M97.7592 97.7587C100.717 94.8012 104.649 93.1724 108.832 93.1724C111.681 93.1724 114.414 93.929 116.802 95.346V68.4004H60V117.918C60 118.777 60.7031 119.48 61.5625 119.48H97.3539C91.6584 113.348 91.793 103.725 97.7592 97.7587Z'
        fill='#EEF6FF'
      />
      <path
        d='M63.5438 117.918V68.4004H60V117.918C60 118.777 60.7031 119.48 61.5625 119.48H65.1063C64.2469 119.48 63.5438 118.777 63.5438 117.918Z'
        fill='#C3DDF4'
      />
      <path
        d='M115.909 60H60.8931C60.4019 60 60 60.4019 60 60.8931V68.8434H116.802V60.8931C116.802 60.4019 116.4 60 115.909 60Z'
        fill='#293D7C'
      />
      <path
        d='M64.4369 60H60.8931C60.4019 60 60 60.4019 60 60.8931V68.8434H63.5438V60.8931C63.5438 60.4019 63.9456 60 64.4369 60Z'
        fill='#1A2B63'
      />
      <path
        d='M84.7284 87.9085C84.7284 88.3382 84.3768 88.6897 83.9471 88.6897H66.5342C66.1045 88.6897 65.7529 88.3382 65.7529 87.9085V75.4211C65.7529 74.9915 66.1045 74.6399 66.5342 74.6399H83.947C84.3767 74.6399 84.7282 74.9915 84.7282 75.4211V87.9085H84.7284Z'
        fill='#69CDFF'
      />
      <path
        d='M69.2967 87.9085V75.4211C69.2967 74.9915 69.6482 74.6399 70.0779 74.6399H66.5342C66.1045 74.6399 65.7529 74.9915 65.7529 75.4211V87.9085C65.7529 88.3382 66.1045 88.6897 66.5342 88.6897H70.0779C69.6482 88.6897 69.2967 88.3382 69.2967 87.9085Z'
        fill='#52BBEF'
      />
      <path
        d='M93.93 104.029H66.5342C66.1045 104.029 65.7529 104.381 65.7529 104.811V112.281C65.7529 112.71 66.1045 113.062 66.5342 113.062H93.7601C92.9359 110.107 92.992 106.957 93.93 104.029Z'
        fill='#FF9518'
      />
      <path
        d='M69.2967 112.281V104.811C69.2967 104.381 69.6482 104.029 70.0779 104.029H66.5342C66.1045 104.029 65.7529 104.381 65.7529 104.811V112.281C65.7529 112.71 66.1045 113.062 66.5342 113.062H70.0779C69.6482 113.062 69.2967 112.71 69.2967 112.281Z'
        fill='#EF8318'
      />
      <path
        d='M93.9295 104.029H88.2578V113.062H93.7597C92.9355 110.107 92.9916 106.957 93.9295 104.029Z'
        fill='#FFD00D'
      />
      <path
        d='M110.118 75.8118H90.9639C90.3167 75.8118 89.792 75.2871 89.792 74.6399C89.792 73.9927 90.3167 73.468 90.9639 73.468H110.118C110.766 73.468 111.29 73.9927 111.29 74.6399C111.29 75.2871 110.766 75.8118 110.118 75.8118Z'
        fill='#91E0E8'
      />
      <path
        d='M110.118 80.4951H90.9639C90.3167 80.4951 89.792 79.9704 89.792 79.3232C89.792 78.6761 90.3167 78.1514 90.9639 78.1514H110.118C110.766 78.1514 111.29 78.6761 111.29 79.3232C111.29 79.9704 110.766 80.4951 110.118 80.4951Z'
        fill='#91E0E8'
      />
      <path
        d='M110.118 85.1782H90.9639C90.3167 85.1782 89.792 84.6535 89.792 84.0063C89.792 83.3592 90.3167 82.8345 90.9639 82.8345H110.118C110.766 82.8345 111.29 83.3592 111.29 84.0063C111.29 84.6535 110.766 85.1782 110.118 85.1782Z'
        fill='#91E0E8'
      />
      <path
        d='M110.118 89.8616H90.9639C90.3167 89.8616 89.792 89.3369 89.792 88.6897C89.792 88.0425 90.3167 87.5178 90.9639 87.5178H110.118C110.766 87.5178 111.29 88.0425 111.29 88.6897C111.29 89.3369 110.766 89.8616 110.118 89.8616Z'
        fill='#91E0E8'
      />
      <path
        d='M106.791 93.3042H65.7529C65.1056 93.3042 64.5811 93.8289 64.5811 94.4761C64.5811 95.1233 65.1056 95.6479 65.7529 95.6479H100.374C102.309 94.4009 104.491 93.6017 106.791 93.3042Z'
        fill='#91E0E8'
      />
      <path
        d='M97.4553 98.072H65.7529C65.1056 98.072 64.5811 98.5967 64.5811 99.2439C64.5811 99.8911 65.1056 100.416 65.7529 100.416H95.6261C96.1514 99.5916 96.7607 98.8059 97.4553 98.072Z'
        fill='#91E0E8'
      />
      <path
        d='M65.4521 65.4077C65.1429 65.4077 64.8412 65.2827 64.6225 65.0654C64.4051 64.8466 64.2803 64.5451 64.2803 64.2358C64.2803 63.9279 64.4053 63.6265 64.6225 63.4077C64.8411 63.189 65.1428 63.064 65.4521 63.064C65.7601 63.064 66.0631 63.1888 66.2803 63.4077C66.499 63.6265 66.624 63.928 66.624 64.2358C66.624 64.5451 66.499 64.8468 66.2803 65.0654C66.0631 65.2827 65.76 65.4077 65.4521 65.4077Z'
        fill='#E26693'
      />
      <path
        d='M69.2949 65.4077C68.987 65.4077 68.684 65.2827 68.4666 65.0638C68.248 64.8466 68.123 64.5451 68.123 64.2358C68.123 63.9279 68.248 63.6265 68.4666 63.4077C68.6854 63.189 68.987 63.064 69.2949 63.064C69.6041 63.064 69.9057 63.1888 70.1246 63.4077C70.3418 63.6265 70.4668 63.928 70.4668 64.2358C70.4668 64.5437 70.3416 64.8468 70.1246 65.0638C69.9057 65.2827 69.6043 65.4077 69.2949 65.4077Z'
        fill='#FFAF10'
      />
      <path
        d='M73.1396 65.4077C72.8303 65.4077 72.5287 65.2827 72.3115 65.0654C72.0928 64.8466 71.9678 64.5451 71.9678 64.2358C71.9678 63.9279 72.0928 63.6265 72.3115 63.4077C72.5287 63.189 72.8303 63.064 73.1396 63.064C73.4475 63.064 73.7506 63.1888 73.9678 63.4077C74.1865 63.6265 74.3115 63.928 74.3115 64.2358C74.3115 64.5451 74.1865 64.8468 73.9678 65.0654C73.749 65.2827 73.4473 65.4077 73.1396 65.4077Z'
        fill='#37C47A'
      />
      <path
        d='M124.026 120.813L120.814 124.025L127.133 130.344C128.266 129.337 129.338 128.265 130.345 127.132L124.026 120.813Z'
        fill='#445EA0'
      />
      <path
        d='M125.301 118.877L122.639 116.215C121.931 117.541 121.02 118.786 119.903 119.903C118.799 121.007 117.559 121.925 116.221 122.644L118.877 125.3C119.181 125.604 119.678 125.604 119.982 125.3L125.301 119.981C125.605 119.678 125.605 119.181 125.301 118.877Z'
        fill='#293D7C'
      />
      <path
        d='M120.7 123.477L118.429 121.205C117.73 121.749 116.992 122.23 116.221 122.643L118.877 125.3C119.181 125.604 119.678 125.604 119.982 125.3L121.805 123.477C121.501 123.781 121.004 123.781 120.7 123.477Z'
        fill='#1A2B63'
      />
      <path
        d='M120.124 97.5376C126.361 103.774 126.361 113.887 120.124 120.124C113.887 126.36 103.775 126.36 97.5379 120.123C91.301 113.887 91.3013 103.774 97.5379 97.5377C103.775 91.3007 113.887 91.3005 120.124 97.5376Z'
        fill='#445EA0'
      />
      <path
        d='M116.75 116.749C112.377 121.122 105.287 121.122 100.913 116.748C96.5406 112.376 96.5405 105.286 100.913 100.913C105.286 96.5398 112.377 96.54 116.75 100.913C121.123 105.286 121.123 112.376 116.75 116.749Z'
        fill='#69CDFF'
      />
      <path
        d='M116.75 116.749C117.148 116.35 117.509 115.929 117.835 115.49C113.451 118.741 107.232 118.38 103.257 114.405C99.2825 110.43 98.9216 104.211 102.172 99.8276C101.733 100.153 101.312 100.514 100.913 100.912C96.5405 105.285 96.5406 112.376 100.913 116.748C105.287 121.122 112.377 121.122 116.75 116.749Z'
        fill='#52BBEF'
      />
      <path
        d='M115.909 60H78.1113V68.8434H116.802V60.8931C116.802 60.4019 116.4 60 115.909 60Z'
        fill='#445EA0'
      />
      <path
        d='M110.755 103.208C111.79 103.208 112.63 102.368 112.63 101.333C112.63 100.297 111.79 99.4578 110.755 99.4578C109.719 99.4578 108.88 100.297 108.88 101.333C108.88 102.368 109.719 103.208 110.755 103.208Z'
        fill='#D9EAFC'
      />
      <path
        d='M114.694 104.609C115.428 104.609 116.022 104.015 116.022 103.281C116.022 102.548 115.428 101.953 114.694 101.953C113.961 101.953 113.366 102.548 113.366 103.281C113.366 104.015 113.961 104.609 114.694 104.609Z'
        fill='#D9EAFC'
      />
    </svg>
  );
}
