import { useContext } from 'react';
import { Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/atoms';
import { TagIcon } from 'components/atoms/icon';
import { ActionsModal } from 'components/organisms';

import { Search } from '../topbar/Search';
import { useContactDelete } from '../useContactDelete';
import { HandlersContext } from './HandlersContext';
import { UserTagsDropdown } from './UserTagsDropdown';
import { CustomCheckbox } from './Styles';

export function Header() {
  const { t } = useTranslation();
  const {
    checkAll,
    checkedList,
    indeterminate,
    onCheckAllChange,
    getSearchedQuery,
    clearSelectedContacts,
  } = useContext<any>(HandlersContext);

  const {
    onContactDelete,
    toggleDeleteConfirm,
    visibleDeleteConfirm,
    deleting,
  } = useContactDelete({ clearSelectedContacts });

  const handleDeleteContact = async () => {
    await onContactDelete(checkedList);
  };
  const handleDelete = () => {
    toggleDeleteConfirm();
  };
  return (
    <>
      <div className='w-full  h-14 flex pt-6 pb-3.5 px-9.5 items-center'>
        <div className='mr-1.5 flex-shrink-0'>
          <CustomCheckbox
            data-cy='contact-select-checkbox'
            indeterminate={indeterminate}
            onChange={onCheckAllChange}
            checked={checkAll}
          />
        </div>
        <div className='w-88 flex-shrink-0 pl-3.5' data-cy='contact-select-search-wrapper'>
          <Search data-cy='contact-select-search' getSearchedQuery={getSearchedQuery} />
        </div>
        <div>
          <Dropdown
            data-cy='contact-select-tag-dropdown'
            overlay={<UserTagsDropdown />}
            trigger={['click']}
          >
            <button
              data-cy='contact-select-tag-dropdown-button'
              className='flex items-center'
              type='button'
              onClick={e => e.preventDefault()}
            >
              <TagIcon data-cy='contact-select-tag-dropdown-icon' className='text-gray' />
              <h6
                data-cy='contact-select-tag-dropdown-title'
                className='text-sm text-gray-500 leading-5 font-semibold ml-1.5 mr-2'
              >
                User Tags
              </h6>
              <Icon name='arrow-down' className='h-4 w-4 text-gray' />
            </button>
          </Dropdown>
        </div>
        {checkedList?.length > 0 && (
          <div className='flex items-center ml-auto'>
            <h6
              data-cy='contacts-selected-count'
              className='text-xs text-info font-semibold leading-3 tracking-wider mr-5'
            >
              {checkedList?.length} Selected
            </h6>
            <button
              data-cy='contacts-selected-count-delete'
              className='bg-primary-50 rounded-lg h-8 px-5 py-2'
              type='button'
              onClick={handleDelete}
            >
              <div className='flex items-center'>
                <Icon name='trash' className='h-4 w-4 mr-1.5 text-error' />
                <h6 className='text-sm text-error font-medium leading-none '>Delete</h6>
              </div>
            </button>
          </div>
        )}
      </div>

      <ActionsModal
        onOk={handleDeleteContact}
        isModalOpen={visibleDeleteConfirm}
        onCancel={toggleDeleteConfirm}
        onModalCancel={toggleDeleteConfirm}
        title={t('deleteContact', 'Delete Contact')}
        info={t('deleteContactConfirmation', 'Are you sure you want to delete this contact?')}
        btnText={t('action.common.delete', 'Delete')}
        action='delete'
        loading={deleting}
        icon={<Icon name='delete-contact' />}
        btnIcon={<Icon name='delete-bin-2' />}
      />
    </>
  );
}
