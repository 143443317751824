import { Dispatch, SetStateAction } from 'react';

import { Headphone2, KeyPad, SignalIcon } from 'components/atoms/icon';
import { Wrapper, SidebarIconWrapper } from './Styles';

import { WIDGET_NAV_SCREENS } from '../constants';

interface SidebarProps {
  selectedNavScreen?: string;
  setSelectedNavScreen?: Dispatch<SetStateAction<string>>;
}

const WidgetSidebar = ({ selectedNavScreen, setSelectedNavScreen }: SidebarProps) => {
  const { CALL_WIDGET, AUDIO_SETTINGS } = WIDGET_NAV_SCREENS;
  const displayScreen = (screen: string) => () => {
    setSelectedNavScreen?.(screen);
  };

  return (
    <Wrapper className='h-full'>
      <SidebarIconWrapper
        className={selectedNavScreen === CALL_WIDGET ? 'active' : ''}
        onClick={displayScreen(CALL_WIDGET)}
      >
        <KeyPad />
      </SidebarIconWrapper>
      {/* strong tag is necessary for dragging functionality */}
      <strong className='flex-grow w-full' />
      <SidebarIconWrapper
        className={selectedNavScreen === AUDIO_SETTINGS ? 'active' : ''}
        onClick={displayScreen(AUDIO_SETTINGS)}
      >
        <Headphone2 />
      </SidebarIconWrapper>
      <SidebarIconWrapper>
        <SignalIcon />
      </SidebarIconWrapper>
    </Wrapper>
  );
};

export default WidgetSidebar;
