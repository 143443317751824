import { ReactNode } from 'react';
import { Control } from 'react-hook-form';

export enum BannerType {
  info = 'info',
  error = 'error',
  warning = 'warning',
  success = 'success',
}

export type Banner = {
  title?: string;
  type?: BannerType;
  icon?: ReactNode;
  showIcon?: boolean;
  closable?: boolean;
  alignContent?: 'center' | 'left';
};

export type PhoneInputFormDataType = {
  number: string;
};

export type SelectedContactType = {
  name: string;
  number: string;
};
export interface PhoneInputContextType {
  searchedText: string;
  control?: Control<any>;
  handleSearch?: (inputText: string) => void;
  handleSelect?: (value: string, options: any) => void;
  autoCompleteContacts?: any;
  selectedContact: SelectedContactType | null;
  handleDialpadClick: (key: string, tone?: number[]) => void;
  disableCall?: boolean;
}
