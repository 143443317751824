import { createContext, useState } from 'react';
import { InformationCircle } from 'components/atoms/icon';

import { Banner, BannerType } from '../types';
import { useCallWidgetHandler } from '../hooks/useCallWidgetHandler';

export const CallWidgetContext = createContext<any>({});

export const CallWidgetProvider = ({ children }: any) => {
  const [banner, setBanner] = useState<Banner>({});

  const showAlertBanner = (text: string, type?: BannerType) => {
    setBanner?.({
      title: text,
      closable: false,
      type: type || BannerType.error,
      icon: <InformationCircle />,
      showIcon: true,
    });
  };

  const { channelInfo, channels, loadingChannels, channelsQueryCalled } = useCallWidgetHandler();

  return (
    <CallWidgetContext.Provider
      value={{
        channelInfo,
        channels,
        loadingChannels,
        channelsQueryCalled,
        banner,
        setBanner,
        showAlertBanner,
      }}
    >
      {children}
    </CallWidgetContext.Provider>
  );
};
