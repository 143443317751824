import styled from 'styled-components';
import { WIDGET_HEIGHT, WIDGET_WIDTH } from './constants';

export const WidgetWrapper = styled.div<any>`
  position: ${props => (props.isDialerPage ? 'fixed' : 'absolute')};
  left: ${props => (props.isDialerPage ? '50%' : 'auto')};
  top: ${props => (props.isDialerPage ? '50%' : 'auto')};
  bottom: ${props => (props.isDialerPage ? 'auto' : '30px')};
  right: ${props => (props.isDialerPage ? 'auto' : '30px')};
  transform: ${props => (props.isDialerPage ? 'translate(-50%,-50%) !important' : 'auto')};
  z-index: 99999;

  * {
    font-family: 'Heebo', 'sans-serif';
  }

  .cursor-drag {
    color: #1a181b;
  }

  .disabled,
  button[disabled] {
    &,
    & * {
      cursor: url('/static-icons/disabled-cursor-light.svg'), auto !important;
    }
  }

  width: ${WIDGET_WIDTH}px;
  min-height: ${WIDGET_HEIGHT}px;
  background: #ffffff;
  border: 1px solid #e7e6eb;
  box-shadow: 0px 4px 28px -4px rgb(66 56 95 / 10%);
  border-radius: 12px;
  & > div:last-child {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  > div {
    min-height: inherit;
  }
`;
