export const PARENT_ROUTE = '/';
// AUTH
export const LOGIN = '/login';
export const INVITE = '/invite';
export const INVITED = '/invited';
export const REGISTER = '/register';
export const REGISTER_TEMPORARY = '/register-temporary/:registerId?';
export const BOOK_DEMO = '/book-demo';
export const ADD_CARD_DETAIL_REGISTER = '/add-card-detail';
export const FORGOT_PASSWORD = '/forgot-password';
export const RESET_PASSWORD = '/reset-password';
export const CHANGE_PASSWORD = '/change-password';
export const LOGIN_OTP_VERIFICATION = '/login/verify-otp';
export const ACCEPT_MEMBER_INVITATION = '/invite/member';

// Dashboard
export const APPLICATION = '/application';
export const ADMIN = '/admin';
export const SUPPORT = '/support';

// Support
export const TICKETS = '/support-tickets';
export const PURCHASE = '/support-purchase';
export const PORTING = '/support-porting';
export const PLAN = '/support-plan';
export const RESTORE_NUMBER = '/restore-number';
export const FEEDBACKS = '/support-feedbacks';
export const WORKSPACES = '/support-workspaces';
export const WORKSPACE_CREDIT = '/support-workspace-credit';
export const NUMBER_RATES = '/support-number-rates';
export const USERS = '/support-users';
export const WORKSPACE_SPAM = '/support-workspace-spam';
export const AUDIT_LOG = '/audit-log';
export const CANCELLED_WORKSPACES = '/support-cancel-workspaces';
export const DELETED_WORKSPACES = '/support-deleted-workspaces';

export const WORKSPACE_PATH = '/w';
export const WORKSPACE_NEW = `${WORKSPACE_PATH}/new`;
export const WORKSPACE_RESTORE = `${WORKSPACE_PATH}/restore`;
export const WORKSPACE_VIEW = `${WORKSPACE_PATH}`;
export const INVOICES = `/view-invoices`;

// SETTINGS PAGES
export const SETTINGS = '/settings';
export const PROFILE = '/profile';
export const WIDGET = '/widget';
export const NUMBERS = '/numbers';
export const MEMBERS = '/members';
export const BLOCK = '/block';
export const TEAM = '/team';
export const TEAMS = '/teams';
export const TAGS = '/tags';
export const TAG = '/tag';
export const CONTACTS = '/contacts';
export const CONTACT = '/contact';
export const WORKSPACE = '/workspace';
export const ACCOUNT_SETUP_GUIDE = '/account-setup-guide';
export const NEW_WORKSPACE = '/new-workspace';
export const INTEGRATION = '/integration';
export const CREDIT = '/credit';
export const BILLING = '/billing-and-plans';
export const PLAN_SUBS = '/plan-subscription';
export const CHOOSE_PLAN = '/choose-plan';
export const DEVICES = '/devices';
export const NOTIFICATION = '/notification';
export const REPORTS = '/reports';
export const LANGUAGE_TIMEZONES = '/language-timezones';
export const CREATE_USER_TICKETS = '/create-tickets';
export const DEVELOPER = '/developer';

export const ADD = '/add';
export const EDIT = '/edit';
export const BUY = '/buy';
export const CREATE = '/create';
export const AUTHORIZATION = '/update-auth';
export const SUPPORT_AUTHORIZATION = '/update-support-auth';
export const PLANSETUP = '/update-plan';
export const SETUP_APPS = '/setup-apps';
export const ACCOUNT_REVIEW = '/support-account-review';
export const EMAIL_VERIFICATION = '/support-email-verification';
export const INCOMPLETE_SIGNUP = '/support-incomplete-signup';
export const COMPLETE_SIGNUP = '/support-complete-signup';

export const DASHBOARD = '/dashboard';
export const DOWNLOAD_APPS = '/download-apps';
export const MOBILE_APPS = '/mobile-apps';
export const DIALER = '/dialer';
export const SALES_DIALER = '/sales-dialer';

// Download apps
export const IOS = '/ios';
export const ANDROID = '/android';
export const CHROME_EXTENSION = '/chrome-extension';
export const WINDOWS = '/windows';
export const MAC = '/mac';
export const LINUX = '/linux';

// Power Dialer
export const CAMPAIGNS = '/campaigns';
export const CALLER_ID = '/caller-id';
export const VOICEMAIL_DROPS = '/voicemail-drops';
export const CALL_SCRIPTS = '/call-scripts';
export const ANALYTICS = '/analytics';
// SMS Campaign
export const BULK_SMS = '/bulk-sms';
export const SMS_CAMPAIGNS = '/sms-campaigns';
export const SMS_CAMPAIGNS_CONTACT_LIST = '/sms-campaigns-contact-list';
export const SMS_TEMPLATES = '/sms-templates';
export const SMS_ANALYTICS = '/sms-analytics';

// helpscout success
export const HELPSCOUT_SUCCESS = '/helpscout-success';
