import { ReactElement, useState, useEffect, useRef, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from 'antd';
import { RiPhoneFill } from 'react-icons/ri';
import isEmpty from 'lodash.isempty';
import { useReactiveVar } from '@apollo/client';

import { useTwilioContext } from 'lib/twilio';
import { CALL_WIDGET_STATUS, CAMPAIGN_STATUS } from 'lib/twilio/constants';
import { activeCallConversationData } from 'services/apollo/reactiveVars';
import { NUMBERS } from 'constants/routes';
import { Icon } from 'components/atoms';
import { useFeatureByPlan, featureList } from 'hooks/useFeatureByPlan';
import WidgetDrawer from 'components/molecules/widget-drawer/WidgetDrawer';
import useRouteChecker from 'components/pages/layouts/useRouteChecker';
import { IWidgetBanner, WidgetBanner } from 'components/molecules/widget-banner/WidgetBanner';
import { nameElipsis } from 'components/utils/helpers';
import { AuthContext } from 'contexts/auth/AuthContext';
import { Action } from '../call-action/Action';
import useCallWidgetContext from '../../hooks/useCallWidgetContext';
import * as S from './Styles';
import { ClientInfo } from '../client-info/ClientInfo';
import { Keypad } from '../keypad/Keypad';
import { ContactAdd } from '../../contact-add/ContactAdd';
import { NoteAdd } from '../note-add/NoteAdd';
import { TagsAssign } from '../tags-assign/TagsAssign';
import { CallTransfer } from '../call-transfer/CallTransfer';
import CampaignWidget from '../../campaign-widget/CampaignWidget';
import { SecondaryCallActions } from '../secondary-call-actions/SecondaryCallActions';
import { SecondaryCampaignActions } from '../secondary-campaign-actions/SecondaryCampaignActions';
import { CampaignCallScript } from '../campaign-call-script/CampaignCallScript';
import { useCallScriptQuery } from '../../hooks/useCallScriptQuery';
import { CampaignNoteAdd } from '../campaign-note-add/CampaignNoteAdd';
import { BannerType } from '../../types';
import { isKYCUnverifiedMsg } from '../../dialer/constants';

export default function WidgetBody(): ReactElement {
  const [disableEndCall, setDisableEndCall] = useState<boolean>(true);
  const [selectedAction, setSelectedAction] = useState<null | string>(null);
  const [showResume, setShowResume] = useState(false);

  const conversationData = useReactiveVar(activeCallConversationData);
  const conversationDataRef: any = useRef();
  conversationDataRef.current = conversationData;

  const history = useHistory();
  const {
    callDurationTime,
    recordingTime,
    mute,
    recording,
    hold,
    loadingCallRecord,
    autoRecordingEnabled,
    loadingCallHold,
    clientInfo,
    channelInfo,
    banner,
    handleCallReject,
    handleToggleMute,
    handleToggleRecording,
    handleToggleHold,
    setBanner,
    showAlertBanner,
    handleClearCampaignNote,
  } = useCallWidgetContext();

  const { isKYCVerified } = useContext(AuthContext);

  const {
    state: {
      status = 'initiated',
      direction,
      callInProgress,
      connection,
      salesDialerWidget,
      transferTo,
      campaignStatus,
    },
  } = useTwilioContext();

  const { pathname } = useLocation();
  const { isDialerPage } = useRouteChecker({
    pathname,
  });

  const callScriptId = connection?.customParameters?.get('callScriptsId');
  const campaignConversationId = connection?.customParameters?.get('campaignConversationId');

  const callScriptTemplate = useCallScriptQuery({ callScriptId });

  const { id: channelId } = channelInfo || {};
  const { number, id: contactId } = clientInfo || {};

  const [hasCallRecordAccess] = useFeatureByPlan(featureList['call-recordings-and-storage']);
  const [hasCallTransferAccess] = useFeatureByPlan(featureList['call-transfer']);
  const isTransferredCall = connection?.customParameters?.get('after_transfer') === 'True';

  useEffect(() => {
    if (status === CALL_WIDGET_STATUS.ANSWERED) {
      setTimeout(() => {
        setDisableEndCall(false);
      }, 5000);
    }
  }, [status]);

  useEffect(() => {
    if (campaignStatus === CAMPAIGN_STATUS.ACTIVE) {
      handleClearCampaignNote?.();
      setSelectedAction(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignStatus]);

  const handleCloseDrawer = () => {
    setSelectedAction(null);
  };

  const drawerContent = (action: string | null) => {
    const actions: any = {
      keypad: <Keypad handleClose={handleCloseDrawer} />,
      'contact-add': <ContactAdd handleClose={handleCloseDrawer} />,
      'note-add': <NoteAdd handleClose={handleCloseDrawer} />,
      'tags-assign': <TagsAssign handleClose={handleCloseDrawer} />,
      'call-transfer': <CallTransfer handleClose={handleCloseDrawer} />,
      'campaign-call-script': (
        <CampaignCallScript
          handleClose={handleCloseDrawer}
          callScriptTemplate={callScriptTemplate}
        />
      ),
      'campaign-note-add': <CampaignNoteAdd handleClose={handleCloseDrawer} />,
      none: '',
    };
    return actions[action || 'none'];
  };

  const drawerHeight = (action: string | null) => {
    const actions: any = {
      keypad: 340,
      'contact-add': 242,
      'note-add': 242,
      'tags-assign': 242,
      'call-transfer': 242,
      'campaign-call-script': 242,
      'campaign-note-add': 242,
      default: 260,
    };
    return actions[action || 'default'];
  };

  const handleTransferClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!hasCallTransferAccess) {
      showAlertBanner?.('This is a premium feature.');
      return;
    }
    setSelectedAction('call-transfer');
  };

  const handleOpenConversation = () => {
    if (!channelId) return;
    if (isDialerPage) {
      window.open(`${NUMBERS}/${channelId}/${contactId ?? `new/?phone=${number}`}`, '_blank');
      return;
    }
    history.push(`${NUMBERS}/${channelId}/${contactId ?? `new/?phone=${number}`}`);
  };

  const handleOpenKeypad = () => {
    setSelectedAction('keypad');
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (status === CALL_WIDGET_STATUS.TRANSFER_FAILED) {
      setShowResume(true);
      const timer = setTimeout(() => {
        setShowResume(false);
      }, 3000);
      return () => clearInterval(timer);
    }
  }, [status]);

  useEffect(() => {
    if (status === CALL_WIDGET_STATUS.TRANSFERRING && transferTo) {
      setBanner?.({
        title: `Call transferring to (${nameElipsis(transferTo, 15)})...`,
        closable: true,
        type: BannerType.info,
        icon: <Icon name='call-forwarded' />,
        showIcon: true,
      });
      return;
    }
    if (status === CALL_WIDGET_STATUS.TRANSFER_FAILED && transferTo) {
      showAlertBanner?.(`Call Transfer to ${nameElipsis(transferTo, 15)} Failed`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, transferTo]);

  useEffect(() => {
    if (!isKYCVerified) {
      setBanner?.({
        title: isKYCUnverifiedMsg,
        closable: true,
        type: BannerType.warning,
        icon: <Icon name='exclamation-triangle' />,
        showIcon: true,
      });
    }
  }, [isKYCVerified, setBanner]);

  const handleCloseBanner = () => {
    setBanner?.({});
  };

  return (
    <S.WidgetBody className='h-full text-center overflow-hidden relative'>
      <WidgetDrawer
        setOpen={setSelectedAction}
        open={selectedAction}
        height={drawerHeight(selectedAction)}
      >
        {drawerContent(selectedAction)}
      </WidgetDrawer>
      {!isEmpty(banner) && (
        <WidgetBanner
          title={banner?.title}
          type={banner?.type}
          icon={banner?.icon}
          showIcon={banner?.showIcon}
          closable={banner?.closable}
          onClose={handleCloseBanner}
        />
      )}
      <ClientInfo />
      {['initiated', 'ringing'].includes(status) ? (
        <p className='text-gray-600 text-sm leading-3.5 font-medium  mt-3 loading'>Calling</p>
      ) : (
        <div className=''>
          <div className='text-gray-200 text-sm leading-3.5 font-medium mt-3 flex justify-center'>
            {status === CALL_WIDGET_STATUS.ENDED ? (
              <>
                <span className='mr-1'>
                  <RiPhoneFill color='#DB312B' size={14} />
                </span>
                <span className='text-error'> {callDurationTime}</span>
                <span className='ml-2.5 text-error'>Call ended</span>
              </>
            ) : (
              <>
                <span className='mr-1'>
                  <RiPhoneFill color='#4C9610' size={14} />
                </span>
                <span className='text-success'> {callDurationTime}</span>
                {hold && <span className='ml-2.5 text-gray-600'>/ On Hold</span>}
                {showResume && <span className='ml-2.5 text-gray-600'>/ Resuming</span>}
              </>
            )}
          </div>
        </div>
      )}
      <div className='relative mt-8'>
        {callInProgress && recording && (
          <div className='absolute bg-info-50 h-6 w-20 py-1 px-3.5 flex  items-center rounded-r-md -top-7'>
            <span className='mr-2'>
              <Icon name='widget_recording' />
            </span>
            <p className='font-medium text-13 text-gray-700 leading-4'>{recordingTime}</p>
          </div>
        )}
        <div className='mx-7.5 flex justify-center items-center gap-x-6 border-b border-gray-50 pb-6 mb-5'>
          <Action
            action='Record'
            icon={recording ? 'widget_record_on' : 'widget_record_stop'}
            onClick={handleToggleRecording}
            className={` ${recording && 'recording active'}`}
            isFeatureAvailable={hasCallRecordAccess}
            disabled={
              !callInProgress || loadingCallRecord || isTransferredCall || !autoRecordingEnabled
            }
            type='primary'
          />
          <Action
            action={hold ? 'Resume' : 'Hold'}
            icon='widget_hold'
            onClick={handleToggleHold}
            className={` ${hold && 'hold active'}`}
            disabled={!callInProgress || loadingCallHold}
            type='primary'
          />
          <Action
            action='Mute'
            icon='widget_mic-off'
            onClick={handleToggleMute}
            className={` ${mute && 'mute active'}`}
            disabled={!callInProgress}
            type='primary'
          />
          <Action
            action='Keypad'
            icon='widget_dialpad'
            onClick={handleOpenKeypad}
            className='relative'
            disabled={!callInProgress}
            type='primary'
          />
        </div>
        {!salesDialerWidget ? (
          <SecondaryCallActions setSelectedAction={setSelectedAction} />
        ) : (
          <SecondaryCampaignActions setSelectedAction={setSelectedAction} />
        )}
      </div>
      <div className='mt-8'>
        <S.FooterBtnWrapper>
          {!salesDialerWidget && (
            <Action
              action='Conversation'
              icon='widget_conversation'
              onClick={handleOpenConversation}
              className='footer-action'
              type='primary'
            />
          )}
          <Button
            onClick={handleCallReject}
            className='end-call'
            disabled={direction === 'Outgoing' && disableEndCall && !salesDialerWidget}
          >
            <div className='flex flex-col items-center justify-center'>
              <Icon name='widget_call-receive' />
            </div>
          </Button>
          {!salesDialerWidget && (
            <Action
              action='Transfer'
              icon='widget_transfer-call'
              onClick={handleTransferClick}
              className='footer-action'
              disabled={
                !callInProgress ||
                !!hold ||
                isTransferredCall ||
                status === CALL_WIDGET_STATUS.TRANSFERRING
              }
              type='primary'
            />
          )}
        </S.FooterBtnWrapper>
      </div>
      {salesDialerWidget && <CampaignWidget />}
    </S.WidgetBody>
  );
}
