import styled from 'styled-components';
import { SelectInput } from 'components/molecules/fields';

import { WIDGET_HEADER_HEIGHT } from '../constants';

export const Wrapper = styled.div`
  height: ${WIDGET_HEADER_HEIGHT}px;
  border-bottom: 1px solid var(--Stroke-100-Default, #e5e4e7);

  svg.icon-with-bg {
    color: #4d4950;
    border-radius: var(--VerticalPadding, 8px);
    background: var(--Background-400, #edebee);
    padding: 4px;
    flex-shrink: 0;
  }
`;

export const ChannelSelectInput = styled(SelectInput)`
  &.ant-select-single:not(.ant-select-customize-input) {
    .ant-select-selector {
      background: none;
      border: none;
      padding: 0;
      height: 54px;
      > .ant-select-selection-item {
        > div {
          padding-left: 10px;
          h4 {
            font-weight: 500;
            margin-bottom: -2px;
            position: relative;
            top: 2px;
          }
        }
      }
      + .ant-select-arrow {
        right: 20px;
        > svg {
          height: 24px;
          width: 24px;
        }
      }
    }
    .ant-select-item {
      padding: 0;
      font-weight: 450;
      border-bottom: 1px solid var(--Stroke-100-Default, #e5e4e7);

      &.ant-select-item-option-selected {
        background: var(--Link-100, #ebf5ff);

        img {
          border-color: #007aff;
        }
      }
    }
    /* Dropdown styles */
    .ant-select-dropdown {
      top: ${WIDGET_HEADER_HEIGHT}px !important;
      padding: 0;
      border-radius: 0;
      box-shadow: none;
      border: none;
      min-width: 293px !important;
      width: 293px !important;
    }
  }
`;

export const ChannelDropdownHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  background: var(--Background-200, #faf9fb);
  h3 {
    color: var(--Text-400-Paragraph, #4d4950);
    font-size: 15px;
    font-weight: 420;
    line-height: 20px;
    + p {
      color: var(--Text-600-Headers, #1a181b);
      font-size: 18px;
      font-weight: 550;
      line-height: 20px;
    }
  }

  > button {
    padding: 7px 11px;
    border-radius: var(--Radius, 10px);
    border: 1px solid var(--Link-300-Default, #007aff);
    background: var(--Background-200, #faf9fb);
    color: var(--Link-300-Default, #007aff);
    font-size: 15px;
    font-weight: 420;
    line-height: 20px;
  }

  + h2 {
    border-top: 1px solid var(--Stroke-100-Default, #e5e4e7);
    border-bottom: 1px solid var(--Stroke-100-Default, #e5e4e7);
    color: var(--Text-600-Headers, #1a181b);
    font-size: 16px;
    font-weight: 450;
    line-height: 150%;
    padding: 13px 16px;
  }
`;

export const ChannelWrapper = styled.div`
  padding: 8px 16px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: var(--Radius, 10px);
  > img {
    object-fit: cover;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    border: 4px solid #f0f0f0;
  }
  h4 {
    max-width: 192px;
    color: var(--Text-600-Headers, #1a181b);
    font-size: 14px;
    font-weight: 450;
    + p {
      color: var(--Text-300-Component-2, #66616b);
      font-size: 13px;
      font-weight: 420;
    }
    &,
    & + p {
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;
