import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { ToastMessage } from 'components/atoms';
import { CALL_RECORDING, CALL_HOLD, REJECT_CONVERSATION } from 'graphql/channel/phoneWidget';
import { SEND_MESSAGE } from 'graphql/channel/conversation';
import { HoldAction, CallDirection } from 'generated/graphql';
import { SKIP_CAMPAIGN_CONVERSATION } from 'graphql/sales-dialer/campaign';
import { CAMPAIGN_CALL_HOLD, CAMPAIGN_CALL_RECORDING } from 'graphql/sales-dialer/call-actions';
import { CAMPAIGN_VOICEMAIL_DROP } from 'graphql/sales-dialer/voicemail';

interface IProps {
  recording?: boolean;
  onCallRecordingSuccess?: () => void;
  onCallHoldSuccess?: () => void;
  onCallSkipSuccess?: (nextId: string, contactNumber: string) => void;
  showSmsLimitWarning?: () => void;
  onDropVoicemailSuccess?: () => void;
}

export function usePhoneMutation({
  onCallRecordingSuccess,
  onCallHoldSuccess,
  onCallSkipSuccess,
  recording,
  showSmsLimitWarning,
  onDropVoicemailSuccess,
}: IProps) {
  const { t } = useTranslation();

  const [callRecording, { loading: loadingCallRecord }] = useMutation(CALL_RECORDING, {
    onCompleted(resData) {
      const { error } = resData?.callRecording || {};
      if (error === null) {
        onCallRecordingSuccess?.();
        return;
      }
      ToastMessage({
        content: `Sorry, recording can't be ${recording ? 'stopped' : 'started'} at the moment`,
        type: 'danger',
      });
    },
    onError() {
      ToastMessage({
        content: `Sorry, recording can't be ${recording ? 'stopped' : 'started'} at the moment`,
        type: 'danger',
      });
    },
  });

  const [campaignCallRecording, { loading: loadingCampaignCallRecord }] = useMutation(
    CAMPAIGN_CALL_RECORDING,
    {
      onCompleted(resData) {
        const { error } = resData?.recordCampaignConversation || {};
        if (error === null) {
          onCallRecordingSuccess?.();
          return;
        }
        ToastMessage({
          content: `Sorry, recording can't be ${recording ? 'stopped' : 'started'} at the moment`,
          type: 'danger',
        });
      },
      onError() {
        ToastMessage({
          content: `Sorry, recording can't be ${recording ? 'stopped' : 'started'} at the moment`,
          type: 'danger',
        });
      },
    },
  );

  const updateCallRecording = async (data: any) => {
    const { action, isCampaignCall, conversationSid, campaignConversationId } = data || {};
    if (isCampaignCall) {
      await campaignCallRecording({
        variables: {
          input: {
            action,
            conversationSid: campaignConversationId,
          },
        },
      });
      return;
    }
    await callRecording({
      variables: {
        data: {
          action,
          by_sid: true,
          conversation_sid: conversationSid,
        },
      },
    });
  };

  const [holdCall, { loading: loadingCallHold }] = useMutation(CALL_HOLD, {
    onCompleted() {
      onCallHoldSuccess?.();
    },
    onError() {
      ToastMessage({
        content: `Sorry, unable to hold this call at the moment.`,
        type: 'danger',
      });
    },
  });

  const [holdCampaignCall, { loading: loadingCampaignCallHold }] = useMutation(CAMPAIGN_CALL_HOLD, {
    onCompleted() {
      onCallHoldSuccess?.();
    },
    onError() {
      ToastMessage({
        content: `Sorry, unable to hold this call at the moment.`,
        type: 'danger',
      });
    },
  });

  const updateCallHold = async (data: any) => {
    const { hold, isCampaignCall, conversationSid, channelId, campaignConversationId } = data || {};
    if (isCampaignCall) {
      await holdCampaignCall({
        variables: {
          data: {
            conversationSid: campaignConversationId,
            hold: !hold,
          },
        },
      });
      return;
    }
    await holdCall({
      variables: {
        data: {
          action: hold ? HoldAction.Unhold : HoldAction.Hold,
          direction: CallDirection.Outbound,
          channel_id: channelId,
          by_sid: true,
          conversation_sid: conversationSid,
        },
      },
    });
  };

  const [rejectConversation] = useMutation(REJECT_CONVERSATION, {
    onError() {
      ToastMessage({
        content: t('error.unspecific', 'Something went wrong.'),
        type: 'danger',
      });
    },
  });

  const updateRejectConversation = async (id: string) => {
    await rejectConversation({
      variables: {
        data: {
          conversationId: id,
        },
      },
    });
  };

  const [sendMessage] = useMutation(SEND_MESSAGE, {
    onCompleted(resData) {
      const { error } = resData.sendMessage;
      if (error === null) {
        ToastMessage({
          content: `${t('toast.sendSmsSuccess', 'Sms sent successfully.')}`,
          type: 'success',
        });
        return;
      }
      if (error?.errorKey === 'limit_exceed') {
        showSmsLimitWarning?.();
        return;
      }
      if (error?.errorKey === 'invalid_input') {
        ToastMessage({
          content: t('error.invalidInput', `The number doesn't support SMS/MMS messages.`),
          type: 'danger',
        });
      }
    },
    onError: () => {
      ToastMessage({ content: t('error.unspecific', 'Something went wrong.'), type: 'danger' });
    },
  });

  const [skipCampaignConversation, { loading: loadingSkipCampaignCall }] = useMutation(
    SKIP_CAMPAIGN_CONVERSATION,
    {
      onCompleted(resData) {
        const { error, data } = resData.skipCampaignConversation;
        if (error === null) {
          const { nextId, contactNumber } = data || {};
          onCallSkipSuccess?.(nextId, contactNumber);
        }
      },
      onError() {
        ToastMessage({
          content: `Sorry, unable to skip call in queue`,
          type: 'danger',
        });
      },
    },
  );

  const skipCampaignCall = async (conversationId: string, campaignId: string) => {
    await skipCampaignConversation({
      variables: {
        data: {
          conversationSid: conversationId,
          campaignId,
        },
      },
    });
  };

  const [campaignVoicemailDrop] = useMutation(CAMPAIGN_VOICEMAIL_DROP, {
    onCompleted(resData) {
      const { error, data } = resData.campaignVoicemailDrop;
      if (error === null) {
        onDropVoicemailSuccess?.();
      }
    },
    onError() {
      ToastMessage({
        content: `Sorry, unable to drop voicemail`,
        type: 'danger',
      });
    },
  });

  const dropVoicemail = async (conversationId: string, campaignId: string) => {
    await campaignVoicemailDrop({
      variables: {
        input: {
          conversationId,
          campaignId,
        },
      },
    });
  };

  return {
    loadingCallRecord: loadingCallRecord || loadingCampaignCallRecord,
    loadingCallHold: loadingCallHold || loadingCampaignCallHold,
    loadingSkipCampaignCall,
    updateCallRecording,
    updateCallHold,
    updateRejectConversation,
    sendMessage,
    skipCampaignCall,
    dropVoicemail,
  };
}
