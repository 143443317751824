import * as touchTone from '../../touch-tone/constants';

// Keypad
export type KeyPadButtonType = {
  digit: string;
  symbol?: string;
  alphabet?: string;
  tone: number[];
};

export const keyPadButtons: KeyPadButtonType[] = [
  {
    digit: '1',
    tone: touchTone.KEY_1,
  },
  {
    digit: '2',
    alphabet: 'ABC',
    tone: touchTone.KEY_2,
  },
  {
    digit: '3',
    alphabet: 'DEF',
    tone: touchTone.KEY_3,
  },
  {
    digit: '4',
    alphabet: 'GHI',
    tone: touchTone.KEY_4,
  },
  {
    digit: '5',
    alphabet: 'JKL',
    tone: touchTone.KEY_5,
  },
  {
    digit: '6',
    alphabet: 'MNO',
    tone: touchTone.KEY_6,
  },
  {
    digit: '7',
    alphabet: 'PQRS',
    tone: touchTone.KEY_7,
  },
  {
    digit: '8',
    alphabet: 'TUV',
    tone: touchTone.KEY_8,
  },
  {
    digit: '9',
    alphabet: 'wxyz',
    tone: touchTone.KEY_9,
  },
  {
    digit: '*',
    tone: touchTone.KEY_STAR,
  },
  {
    digit: '0',
    symbol: '+',
    tone: touchTone.KEY_0,
  },
  {
    digit: '#',
    tone: touchTone.KEY_POUND,
  },
];
