export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const ANALYTICS_ENDPOINT = process.env.REACT_APP_ANALYTICS_ENDPOINT;
export const BASE_URI = process.env.REACT_APP_BASE_URI;
export const SUBSCRIPTION_ENDPOINT = process.env.REACT_APP_SUBSCRIPTION_ENDPOINT;
export const ANALYTICS_SUB_ENDPOINT = process.env.REACT_APP_ANALYTICS_SUB_ENDPOINT;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
export const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
export const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
export const INTERCOM_TEST_ID = process.env.REACT_APP_INTERCOM_TEST_ID;
export const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
export const CAPTCHA_SITE_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY;
export const CHARGEBEE_SITE = process.env.REACT_APP_CHARGEBEE_SITE;
export const CHARGEBEE_PUBLISHABLE_KEY = process.env.REACT_APP_CHARGEBEE_PUBLISHABLE_KEY;
export const EMAIL_CLIENT_SERVICE_ID = process.env.REACT_APP_EMAIL_CLIENT_SERVICE_ID;
export const EMAIL_CLIENT_TEMPLATE_ID = process.env.REACT_APP_EMAIL_CLIENT_TEMPLATE_ID;
export const EMAIL_CLIENT_PUBLIC_KEY = process.env.REACT_APP_EMAIL_CLIENT_PUBLIC_KEY;
export const AUTOMATION_API_ENDPINT = process.env.REACT_APP_AUTOMATION_API_ENDPINT;
export const SALES_DIALER_API_ENDPINT = process.env.REACT_APP_SALES_DIALER_API_ENDPINT;
export const BULK_SMS_API_ENDPOINT = process.env.REACT_APP_SALES_API_ENDPOINT;
