/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextAreaInput } from 'components/atoms/fields';
import { Label, Error } from 'components/atoms';
import { InputFieldWrapper, FlexColumn } from './styled';
import { InputProps } from './types';

// const TextAreaField = React.forwardRef<HTMLInputElement, InputProps>((props: InputProps, ref) => {
const TextAreaField: React.FC<InputProps> = React.forwardRef((props, ref) => {
  const {
    label,
    labelWeight,
    id,
    name,
    value,
    placeholder,
    required = false,
    autoSize,
    showCount,
    errors,
    onChange,
    onBlur,
    onClick,
    onPressEnter,
    control,
    className,
    disabled,
    isEditable,
    ...rest
  } = props;
  const { t } = useTranslation();
  return (
    <>
      <InputFieldWrapper data-cy='text-area'>
        <FlexColumn>
          {label && (
            <Label id={id || name} required={required} fontWeight={labelWeight}>
              {label}
            </Label>
          )}
          {!control && (
            <TextAreaInput
              {...rest}
              autoSize={autoSize}
              placeholder={placeholder}
              id={id || name}
              ref={ref}
              name={name}
              value={value}
              className={className}
              onChange={onChange}
              onBlur={onBlur}
              onClick={onClick}
              disabled={disabled}
              onPressEnter={onPressEnter}
            />
          )}

          {control && (
            <Controller
              name={name}
              control={control}
              render={({ field }) => {
                return isEditable === false ? (
                  <p
                    className={`${field.value ? 'word-break' : 'input-placeholder'}`}
                    onClick={(event: React.MouseEvent<HTMLInputElement>) => {
                      if (onClick) {
                        onClick(event);
                      }
                    }}
                  >
                    {field.value || placeholder}
                  </p>
                ) : (
                  <>
                    <TextAreaInput
                      {...field}
                      {...rest}
                      placeholder={placeholder}
                      id={id || name}
                      value={field.value}
                      showCount={showCount}
                      className={className}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        if (onChange) {
                          onChange(event);
                          field.onChange(event);
                        } else {
                          field.onChange(event);
                        }
                      }}
                      onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                        if (onBlur) {
                          onBlur(event);
                        } else {
                          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                          field?.onBlur && field.onBlur();
                        }
                      }}
                      onClick={(event: React.MouseEvent<HTMLInputElement>) => {
                        if (onClick) {
                          onClick(event);
                        }
                      }}
                      disabled={disabled}
                      onPressEnter={onPressEnter}
                      autoSize={autoSize}
                      ref={ref}
                    />
                  </>
                );
              }}
            />
          )}
          {errors?.[name] && <Error>{t(`constants:${errors[name].message}`)}</Error>}
        </FlexColumn>
      </InputFieldWrapper>
    </>
  );
});

TextAreaField.displayName = 'TextAreaField';
export default TextAreaField;
