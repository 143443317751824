import { useCampaignMutation } from './useCampaignMutation';

export const useCampaignHandlers = () => {
  const { runCampaign } = useCampaignMutation({});
  const handleCampaignRun = (id: string, action: string) => {
    const payload = { id, action };
    runCampaign(payload);
  };
  return {
    handleCampaignRun,
  };
};
