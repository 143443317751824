import { memo, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';

import { MainLayout as MainContainer } from 'components/atoms';
import {
  // EmailVerificationReminder,
  AccountSuspendNotification,
  WebRtcTestFailedNotification,
} from 'components/organisms';
import { NavHelperProvider } from 'components/organisms/navigation/NavHelperContext';
import { useRestoreWorkspace } from 'components/organisms/workspace/useRestoreWorkspace';
import {
  RestoreModal,
  CancelSubscriptionModal,
  UnderReview,
} from 'components/organisms/workspace/modals';
import { WorkspaceListSidebar, WorkspaceDetailSidebar } from 'components/organisms/navigation';
import { refreshPage } from 'components/utils/helpers';
import { useLoggedInMember } from 'hooks/useLoggedInMember';
import { useChatBot } from 'hooks/useChatBot';
import FallbackSpinner from 'components/atoms/spinner/Spinner';
import { ErrorBoundary } from 'components/pages/error-boundary';
import ErrorFallback from 'components/pages/errors/ErrorFallback';
import useReportErrorsToServices from 'services/tracking-and-reporting';
import {
  loadingSwitchWorkspace,
  webRtcPeerConnectionEnabled,
  smsLimitExceededWarning,
  campaignReattemptVar,
  showWorkspaceDetailDrawer,
  isNumberPurchasedWks,
  isNetworkAvailable,
} from 'services/apollo/reactiveVars';
import { useTwilioContext } from 'lib/twilio';
import DeviceOfflineNotification from 'components/organisms/sticky-reminders/device-offline-notification';
import SubsExpiredNotification from 'components/organisms/sticky-reminders/subs-expired';
import NumberSubsNotification from 'components/organisms/sticky-reminders/number-subs-expired/NumberSubsNotification';
import KYCNotification from 'components/organisms/sticky-reminders/kyc';
import SmsLimitModal from 'components/organisms/conversation/client-to-agent/conversation-box/SmsLimitModal';
import InfoModal from 'components/organisms/modals/InfoModal/InfoModal';
import PurchaseNumber from 'components/organisms/sticky-reminders/purchase-number';
import GracePeriodNotification from 'components/organisms/sticky-reminders/grace-period';
import PaypalNotification from 'components/organisms/sticky-reminders/paypal-notification';
import DialerWidget from 'components/organisms/widgets/call-widget';
import DialerWidgetV3 from 'components/organisms/widgets-v3';
import { ENABLE_DIALER_V3 } from 'constants/featureFlags';
import { useMobilePopUp } from 'hooks/useMobilePopUp';

import ReattemptCampaign from '../sales-dialer/campaigns/ReattemptCampaign';

const WorkspaceContentLayout = styled.div`
  height: inherit;
  display: flex;
`;
const AlertContainer = styled.div`
  height: 36px;
  ~ main {
    height: calc(100vh - 36px); // window height - height of alertbar
  }
`;

function MainLayout({ children }: any) {
  const { state: twilliowState, deviceStatus } = useTwilioContext();
  const { openChat } = useChatBot();
  const { onRestore } = useRestoreWorkspace();
  const { reportErrorToServices } = useReportErrorsToServices();
  const {
    isDeletedWorkspace,
    isSubscriptionCancelled,
    isSubscriptionExpired,
    isAlertBarVisible,
    isUserSuspended,
    isUnderReview,
    isAutoRenewFailed,
    isNumberSubscriptionExpired,
    isKYCVerified,
    userRole,
    isOnGracePeriod,
    isPaypalDefaultCard,
  } = useLoggedInMember();

  const isDeviceOffline = deviceStatus === 'offline';

  const internetConnection = useReactiveVar(isNetworkAvailable);
  const loadingWorkspace = useReactiveVar(loadingSwitchWorkspace);
  const webRtcPeerConnection = useReactiveVar<any>(webRtcPeerConnectionEnabled);
  const smsLimitWarningVisible = useReactiveVar<any>(smsLimitExceededWarning);
  const reattemptCampaignVisible = useReactiveVar<any>(campaignReattemptVar);
  const numberPurchasedWorkspace = useReactiveVar<any>(isNumberPurchasedWks);
  const { showMobileInfoModal, openAppUrl, toggleMobileInfoModal } = useMobilePopUp();

  const toggleSmsLimitModal = () => {
    smsLimitExceededWarning(!smsLimitWarningVisible);
  };

  const toggleReattemptModal = () => {
    campaignReattemptVar(!reattemptCampaignVisible);
  };

  const handleContactSupport = () => {
    openChat();
    toggleSmsLimitModal();
  };

  const location = useLocation();

  useEffect(() => {
    // close workspace detail sidebar on route change in mobile & tablet screen
    showWorkspaceDetailDrawer(false);
  }, [location]);
  return (
    <>
      {twilliowState?.showPhoneWidget && (ENABLE_DIALER_V3 ? <DialerWidgetV3 /> : <DialerWidget />)}
      {/* <DialerWidget /> */}
      <>
        {!loadingWorkspace && (isAlertBarVisible || isDeviceOffline || !numberPurchasedWorkspace) && (
          <AlertContainer>
            {!isKYCVerified && <KYCNotification />}
            {isPaypalDefaultCard && <PaypalNotification />}
            {isDeviceOffline && <DeviceOfflineNotification />}
            {/* Show alert banner if at least one number is not purchased in new workspace */}
            {!numberPurchasedWorkspace && internetConnection && <PurchaseNumber />}
            {/* Hide email verification banner as we have implemented the otp verification step */}
            {/* {!isEmailVerifiedUser && <EmailVerificationReminder />} */}
            {!isUnderReview && isSubscriptionExpired && <SubsExpiredNotification />}
            {isOnGracePeriod && <GracePeriodNotification />}
            {isUserSuspended && <AccountSuspendNotification />}
            {!webRtcPeerConnection && <WebRtcTestFailedNotification />}
            {(isAutoRenewFailed || isNumberSubscriptionExpired) && <NumberSubsNotification />}
          </AlertContainer>
        )}
        <MainContainer>
          {/* SMS limit exceed warning modal for kyc unverified users */}
          <SmsLimitModal
            visible={smsLimitWarningVisible}
            toggle={toggleSmsLimitModal}
            onOk={handleContactSupport}
          />
          {/* Campaign reattempt modal for power dialer */}
          {reattemptCampaignVisible && (
            <ReattemptCampaign visible={reattemptCampaignVisible} toggle={toggleReattemptModal} />
          )}
          {/* --------------------------------------------------------- */}

          <InfoModal
            isModalOpen={showMobileInfoModal}
            title='Using mobile?'
            info='Download our mobile app today for better experience.'
            onCancel={toggleMobileInfoModal}
            width={230}
            btnText='Download Now'
            onOk={openAppUrl}
            closable
          />

          <WorkspaceListSidebar />
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onError={reportErrorToServices}
            onReset={refreshPage}
          >
            <WorkspaceContentLayout>
              {loadingWorkspace ? (
                <FallbackSpinner />
              ) : (
                <>
                  <NavHelperProvider>
                    <WorkspaceDetailSidebar />
                    {children}
                  </NavHelperProvider>
                </>
              )}
            </WorkspaceContentLayout>
            {isSubscriptionCancelled && !isDeletedWorkspace && !isSubscriptionExpired && (
              <CancelSubscriptionModal open={isSubscriptionCancelled} onOk={openChat} />
            )}
            {isUnderReview && <UnderReview open={isUnderReview} />}
            {isDeletedWorkspace && <RestoreModal open={isDeletedWorkspace} onOk={onRestore} />}
          </ErrorBoundary>
        </MainContainer>
      </>
    </>
  );
}

export default memo(MainLayout);
