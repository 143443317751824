import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';

import uuid from 'components/utils/uuid';
import * as path from 'constants/routes';
import { routes } from 'services/Route/RouteConfig';
import { ErrorRoute, ProtectedRoute } from 'services/Route';
import FallbackSpinner from 'components/atoms/spinner/Spinner';
import IndexLayout from 'components/pages/layouts/IndexLayout';
import withNetworkDetector from 'services/withNetworkDetector';
import { getSubdomain } from 'components/utils/url';
import { INTERCOM_APP_ID, INTERCOM_TEST_ID } from 'constants/endpoint';
import { AppProvider } from 'contexts/app/AppContext';

function AppRouter() {
  const subdomain = getSubdomain();
  const { pathname } = window.location;

  // const isIntercomDisabled = pathname?.includes('dialer') || pathname?.includes('support');
  const isIntercomDisabled = true;

  const INTERCOM_ID = subdomain.includes('app') ? INTERCOM_APP_ID : INTERCOM_TEST_ID;

  return (
    <AppProvider>
      <IntercomProvider
        appId={INTERCOM_ID as string}
        initializeDelay={10000}
        autoBoot={!isIntercomDisabled}
        shouldInitialize={!isIntercomDisabled}
      >
        <React.Suspense fallback={<FallbackSpinner />}>
          <IndexLayout>
            <Switch>
              <Redirect exact path='/' to={path.DASHBOARD} />
              {routes.map(route => (
                <ProtectedRoute {...route} key={uuid()} />
              ))}
              <ErrorRoute path='*' />
            </Switch>
          </IndexLayout>
        </React.Suspense>
      </IntercomProvider>
    </AppProvider>
  );
}

export default withNetworkDetector(AppRouter);
