import { memo } from 'react';
import { useLocation } from 'react-router-dom';
import Draggable from 'react-draggable';
import styled from 'styled-components';

import useRouteChecker from 'components/pages/layouts/useRouteChecker';

import { WIDGET_HEADER_HEIGHT, WIDGET_HEIGHT } from './constants';
import { WidgetWrapper } from './Styles';
import { useCallWidgetDragger } from './hooks/useCallWidgetDragger';
import WidgetHeader from './widget-header';
import WidgetBody from './WidgetBody';

const Wrapper = styled.div`
  min-height: ${WIDGET_HEIGHT}px;
  > section {
    display: flex;
    height: calc(${WIDGET_HEIGHT}px - ${WIDGET_HEADER_HEIGHT}px);
  }
`;

function Widget() {
  const { pathname } = useLocation();
  const { isDialerPage } = useRouteChecker({
    pathname,
  });
  const draggableProps = useCallWidgetDragger();

  return (
    <Draggable {...draggableProps}>
      <WidgetWrapper className='overflow-hidden' isDialerPage={isDialerPage}>
        <Wrapper data-cy='d-wrapper' className='relative h-full'>
          <WidgetHeader />
          <WidgetBody />
        </Wrapper>
      </WidgetWrapper>
    </Draggable>
  );
}

export default memo(Widget);
