import { PhoneDownFill } from 'components/atoms/icon';
import { useTwilioContext } from 'lib/twilio';

import { ActiveCallActions } from './Styles';
import { CallAction, ICallActionProps } from './call-action';
import { useCallActions } from './hooks/useCallActions';

import ClientInfo from '../common/client-info';
import useActiveCallWidgetContext from '../hooks/useActiveCallWidgetContext';

const CallActive = () => {
  const { handleCallReject } = useActiveCallWidgetContext();

  const {
    state: { direction },
  } = useTwilioContext();

  const { disableEndCall, actionsToShow } = useCallActions();

  return (
    <>
      <ClientInfo />
      <ActiveCallActions>
        {actionsToShow?.map((callAction: ICallActionProps) => (
          <CallAction
            key={callAction?.label}
            onClick={callAction?.onClick}
            label={callAction?.label}
            icon={callAction?.icon}
            disabled={callAction?.disabled}
            variant={callAction?.variant}
          />
        ))}
      </ActiveCallActions>
      <CallAction
        onClick={handleCallReject}
        variant='secondary'
        className='mt-6 mx-auto block'
        icon={<PhoneDownFill />}
        disabled={direction === 'Outgoing' && disableEndCall}
      />
    </>
  );
};

export default CallActive;
