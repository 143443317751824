import isEmpty from 'lodash.isempty';

import WidgetBanner from './widget-banner';
import useCallWidgetContext from './hooks/useCallWidgetContext';
import { useDrawerComponent } from './hooks/useDrawerComponent';

export const DrawerBannerWrapper = ({
  className,
  children,
}: {
  className?: string;
  children?: any;
}) => {
  const { banner } = useCallWidgetContext();

  const { drawerComponent } = useDrawerComponent();

  return (
    <div className={`h-full grid relative content-start ${className ?? ''}`}>
      {drawerComponent}
      {!isEmpty(banner) && (
        <WidgetBanner
          title={banner?.title}
          icon={banner?.icon}
          type={banner?.type}
          showIcon={banner?.showIcon}
          closable={banner?.closable}
          alignContent={banner?.alignContent ?? 'left'}
        />
      )}
      {children ?? <></>}
    </div>
  );
};
