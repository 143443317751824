import { useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';

import { GET_CHANNELS } from 'graphql/channel';

export function useChannelsQuery() {
  const [fetchChannels, { data, loading, called }] = useLazyQuery(GET_CHANNELS, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    fetchChannels();
  }, [fetchChannels]);

  const numbers: never[] = useMemo(() => data?.channels?.data ?? [], [data]);

  return {
    numbers,
    loading,
    called,
  };
}
