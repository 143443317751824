import { ReactElement, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash.isempty';

import { NOTES_MAX_LENGTH } from 'schema-validation';

import { StyledTextAreaInput } from './Styles';
import { schema } from './schema';
import SaveDiscardActionBlock from '../save-discard-action';
import useActiveCallWidgetContext from '../../hooks/useActiveCallWidgetContext';
import useCallWidgetDrawerContext from '../../hooks/useCallWidgetDrawerContext';

export default function NoteAdd(): ReactElement {
  const { t } = useTranslation();
  const { closeDrawer, saveButtonLabel } = useCallWidgetDrawerContext();

  const { note, handleNoteSave, loadingSaveNote } = useActiveCallWidgetContext();

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm<{ title: string }>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      title: note?.title || '',
    },
  });

  const formSubmit = async (val: any) => {
    const { title } = val;
    if (!isEmpty(errors)) return;
    handleNoteSave?.(title);
  };

  useEffect(() => {
    if (note) {
      setValue('title', note?.title);
    }
  }, [note, setValue]);

  const FORM_ID = 'add-notes-form';

  return (
    <form
      className='flex flex-col h-full px-4 pb-4'
      id={FORM_ID}
      onSubmit={handleSubmit(formSubmit)}
    >
      <StyledTextAreaInput
        name='title'
        id='noteBox'
        control={control}
        placeholder={t('writeNotes', 'Write notes...')}
        maxLength={NOTES_MAX_LENGTH}
        autoSize={{ minRows: 10 }}
      />

      <SaveDiscardActionBlock
        isSaveLoading={loadingSaveNote}
        onDiscard={closeDrawer}
        formId={FORM_ID}
        htmlType='submit'
        saveButtonLabel={saveButtonLabel}
      />
    </form>
  );
}
