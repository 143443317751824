import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from 'graphql/fragments/error';
import { AGENT_NODE } from '../fragments';

export const SAVE_IVR_SETTINGS = gql`
  mutation saveIvrSettings($channel: ShortId!, $data: IvrSettingsInputData!) {
    saveIvrSettings(channel: $channel, data: $data) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const SAVE_IVR_SETTINGS_NEW = gql`
  mutation saveMultilevelIvrSettings($data: MultilevelIvrSettingsInput!) {
    saveMultilevelIvrSettings(data: $data) {
      status
      error {
        message
      }
    }
  }
`;

export const GET_IVR_RECORDINGS = gql`
  mutation getIvrRecordingPresignedUrl($channel: ShortId!) {
    getIvrRecordingPresignedUrl(channel: $channel) {
      status
      error {
        code
        message
      }
      data {
        presignedUrl
        recordingUrl
      }
    }
  }
`;

export const UPDATE_CALL_STRATEGY = gql`
  mutation updateCallStrategy($channel: ShortId!, $data: CallStrategySettingsInput!) {
    updateCallStrategy(channel: $channel, data: $data) {
      status
      data {
        incomingCallStrategy
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const TOGGLE_SMS_FORWARDING = gql`
  mutation toggleSmsForwarding($data: SmsForwardingInput!, $channel: ShortId!) {
    toggleSmsForwarding(data: $data, channel: $channel) {
      status
      data {
        smsForward
        smsForwardNumber
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const PORT_NUMBER = gql`
  mutation portNumber($data: PortNumberInputData!) {
    portNumber(data: $data) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
// (WIP) Need to change mutation name and fields
export const EDIT_GENERAL_SETTINGS = gql`
  mutation updateGeneralSettings($channel: ShortId!, $data: GeneralChannelSettingsInput!) {
    updateGeneralSettings(channel: $channel, data: $data) {
      status
      data {
        name
        autoRecordCalls
        internationalCallAndMessages
        emailNotification
        transcription
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const EDIT_CHANNEL_ACCESS = gql`
  mutation editChannelAccess($channel: ShortId!, $data: EditChannelShareInput!) {
    editChannelAccess(channel: $channel, data: $data) {
      status
      data {
        id
        shared
        agents {
          ...AgentFields
        }
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
  ${AGENT_NODE}
`;
export const DELETE_CHANNEL = gql`
  mutation deleteChannel($id: ShortId!) {
    deleteChannel(id: $id) {
      status
      data {
        id
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const MAKE_NUMBER_FREE = gql`
  mutation makeNumberFree($data: WorkspaceNumber!) {
    makeNumberFree(data: $data) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
export const UPDATE_CHANNEL_ABILITIES = gql`
  mutation updateChannelAbilities($data: EditChannelAbilities!) {
    updateChannelAbilities(data: $data) {
      status
      data {
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const SETUP_CALLER_ID = gql`
  mutation setupCallerId($data: CallerIdNumberInput!) {
    setupCallerId(data: $data) {
      status
      data {
        code
        message
        status
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const SET_CHANNEL_CALLER_ID = gql`
  mutation setChannelCallerId($data: CallerIdNumberInput!) {
    setChannelCallerId(data: $data) {
      status
      data {
        callerId
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const REMOVE_CHANNEL = gql`
  mutation removeChannel($data: RemoveChannelInput!) {
    removeChannel(data: $data) {
      status
      data {
        id
        success
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

export const GENERATE_SIP_SETTINGS = gql`
  mutation sipTrunking($data: SipTrunkingInput!) {
    sipTrunking(data: $data) {
      status
      data {
        username
        password
        domainName
      }
      error {
        ...Error
      }
    }
  }
  ${ERROR_FRAGMENT}
`;
